import React, { Component } from 'react';
import { Image, StyleSheet, FlatList, View, TextInput, Text, ActivityIndicator, Platform } from 'react-native';
import { Button } from 'react-native-elements';
import { AsyncStorage } from 'react-native';
import { Redirect } from 'react-router';
import path from './serverPath.js'
import SwitchSelector from 'react-native-switch-selector';

class OrderDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            is_edit: false,

            order: null,
            delivery_address: '',
            delivery_date: '',
            had_member: false,
            receiver_contact: "",
            receiver_email: "",
            receiver_name: "",
            remark: "",
            sender_contact: "",
            sender_email: "",
            sender_name: "",

            enable_options: [],

            order_options:[],
            order_status: 0,
            payment_status:0,
            order_from: "",
        };
    }

    async onPressButton() {
        this.props.history.push('/orderList');
    }

    async onEditButtonPress() {
        this.setState({
            is_edit: true
        })
    }

    async onUpdate() {
        this.setState({
            is_edit: false
        })
    }

    async componentDidMount() {
        const uuid = await AsyncStorage.getItem('uuid');
        const token = await AsyncStorage.getItem('token');
        const user_info = await AsyncStorage.getItem('user_info');
        const userInfo = JSON.parse(user_info);

        getOrderDetail(uuid, token, userInfo.email, this.props.location.state.order_id).then(data => {
            var order = data.data.order

            var delivery_address = data.data.order.delivery_address
            var delivery_date = data.data.order.delivery_date
            var receiver_contact = data.data.order.receiver_contact
            var receiver_email = data.data.order.receiver_email
            var receiver_name = data.data.order.receiver_name
            var remark = data.data.order.remark
            var sender_contact = data.data.order.sender_contact
            var sender_email = data.data.order.sender_email
            var sender_name = data.data.order.sender_name
            var order_from = data.data.order.from == "customer"?"yes":"no"

            if (delivery_address == '') {
                delivery_address = '-'
            }

            if (delivery_date == '') {
                delivery_date = '-'
            }

            if (receiver_contact == '') {
                receiver_contact = '-'
            }

            if (receiver_email == '') {
                receiver_email = '-'
            }

            if (receiver_name == '') {
                receiver_name = '-'
            }

            if (remark == '') {
                remark = '-'
            }

            if (sender_contact == '') {
                sender_contact = '-'
            }

            if (sender_email == '') {
                sender_email = '-'
            }

            if (sender_name == '') {
                sender_name = '-'
            }

            var member = data.data.order.member
            var had_member = false
            if (member != null) {
                had_member = true
            }

            const enable_options = [
                { label: "處理中Pending", value: 'pending' },
                { label: "已付款Paid", value: 'paid' },
                { label: "已失敗Failed", value: 'failed' },
            ];

            const order_options = [
                { label: "處理中Pending", value: 'pending' },
                { label: "已付款Paid", value: 'paid' },
                { label: "送貨中Delivering", value: 'delivering' },
                { label: "已完成Completed", value: 'completed' },
                { label: "已取消Cancelled", value: 'cancelled' },
            ];

            var order_status = 0;
            var payment_status = 0;

            if(data.data.order.status == 'pending')
            {
                order_status = 0;
            }
            else if(data.data.order.status == 'paid')
            {
                order_status = 1;
            }
            else if(data.data.order.status == 'delivering')
            {
                order_status = 2;
            }
            else if(data.data.order.status == 'completed')
            {
                order_status = 3;
            }
            else if(data.data.order.status == 'cancelled')
            {
                order_status = 4;
            }

            console.log(order)
            this.setState({
                order_options,
                enable_options,
                order,
                delivery_address,
                delivery_date,
                had_member,
                receiver_contact,
                receiver_email,
                receiver_name,
                remark,
                sender_contact,
                sender_email,
                sender_name,
                order_status,
                order_from,
            });
        });
    }

    async switchEnableSelected(value, item) {
        const uuid = await AsyncStorage.getItem('uuid');
        const token = await AsyncStorage.getItem('token');
        const user_info = await AsyncStorage.getItem('user_info');
        const userInfo = JSON.parse(user_info);

        updatePaymentStatus(uuid, token, userInfo.email, value, item.payment_id).then(data => {
            console.log(data)
            var order = data.data.order

            var delivery_address = data.data.order.delivery_address
            var delivery_date = data.data.order.delivery_date
            var receiver_contact = data.data.order.receiver_contact
            var receiver_email = data.data.order.receiver_email
            var receiver_name = data.data.order.receiver_name
            var remark = data.data.order.remark
            var sender_contact = data.data.order.sender_contact
            var sender_email = data.data.order.sender_email
            var sender_name = data.data.order.sender_name

            if (delivery_address == '') {
                delivery_address = '-'
            }

            if (delivery_date == '') {
                delivery_date = '-'
            }

            if (receiver_contact == '') {
                receiver_contact = '-'
            }

            if (receiver_email == '') {
                receiver_email = '-'
            }

            if (receiver_name == '') {
                receiver_name = '-'
            }

            if (remark == '') {
                remark = '-'
            }

            if (sender_contact == '') {
                sender_contact = '-'
            }

            if (sender_email == '') {
                sender_email = '-'
            }

            if (sender_name == '') {
                sender_name = '-'
            }

            var member = data.data.order.member
            var had_member = false
            if (member != null) {
                had_member = true
            }

            console.log(order)
            this.setState({
                order,
                delivery_address,
                delivery_date,
                had_member,
                receiver_contact,
                receiver_email,
                receiver_name,
                remark,
                sender_contact,
                sender_email,
                sender_name,
            });
        })
    }

    async switchEnableStatusSelected(value) {
        const uuid = await AsyncStorage.getItem('uuid');
        const token = await AsyncStorage.getItem('token');
        const user_info = await AsyncStorage.getItem('user_info');
        const userInfo = JSON.parse(user_info);

        updateOrderStatus(uuid, token, userInfo.email, value, this.state.order.order_id).then(data => {
            console.log(data)
            var order = data.data.order

            var delivery_address = data.data.order.delivery_address
            var delivery_date = data.data.order.delivery_date
            var receiver_contact = data.data.order.receiver_contact
            var receiver_email = data.data.order.receiver_email
            var receiver_name = data.data.order.receiver_name
            var remark = data.data.order.remark
            var sender_contact = data.data.order.sender_contact
            var sender_email = data.data.order.sender_email
            var sender_name = data.data.order.sender_name

            if (delivery_address == '') {
                delivery_address = '-'
            }

            if (delivery_date == '') {
                delivery_date = '-'
            }

            if (receiver_contact == '') {
                receiver_contact = '-'
            }

            if (receiver_email == '') {
                receiver_email = '-'
            }

            if (receiver_name == '') {
                receiver_name = '-'
            }

            if (remark == '') {
                remark = '-'
            }

            if (sender_contact == '') {
                sender_contact = '-'
            }

            if (sender_email == '') {
                sender_email = '-'
            }

            if (sender_name == '') {
                sender_name = '-'
            }

            var member = data.data.order.member
            var had_member = false
            if (member != null) {
                had_member = true
            }

            console.log(order)
            this.setState({
                order,
                delivery_address,
                delivery_date,
                had_member,
                receiver_contact,
                receiver_email,
                receiver_name,
                remark,
                sender_contact,
                sender_email,
                sender_name,
            });
        })
    }


    render() {
        if (this.state.order != null && !this.state.is_edit) {
            let button = null
            if(this.state.order_from == "yes")
            {
                button = <Button style={{ marginTop: 10 }} large title="編輯 Edit" onPress={this.onEditButtonPress.bind(this)} />
            }

            return (
                <View style={styles.container}>
                    <Image style={styles.image} source={require('./image/hippos_banner.png')} />
                    <Text style={styles.title}>帳單詳情 Order Detail</Text>
                    <View style={styles.login_border}>
                        <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>帳單編號Order Number</Text>
                            <Text style={styles.rightlistedItem}>{this.state.order.order_number}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>下單店鋪 Ordering Shop</Text>
                            <Text style={styles.rightlistedItem}>{this.state.order.shop.name}</Text>
                        </View>
                        {this.state.had_member &&
                            <View style={{ flexDirection: 'row', width: "100%" }}>
                                <Text style={styles.leftlistedItem}>會員編號 Member No</Text>
                                <Text style={styles.rightlistedItem}>{this.state.order.member.member_no}</Text>
                            </View>
                        }
                        {this.state.had_member &&
                            <View style={{ flexDirection: 'row', width: "100%" }}>
                                <Text style={styles.leftlistedItem}>會員名稱 Member Nmae</Text>
                                <Text style={styles.rightlistedItem}>{this.state.order.member.name}</Text>
                            </View>
                        }
                        {this.state.had_member &&
                            <View style={{ flexDirection: 'row', width: "100%" }}>
                                <Text style={styles.leftlistedItem}>會員電話 Member Phone</Text>
                                <Text style={styles.rightlistedItem}>{this.state.order.member.phone}</Text>
                            </View>
                        }

                        {/* <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>下單員工 Ordering Staff</Text>
                            <Text style={styles.rightlistedItem}>{this.state.order.user_info.name}</Text>
                        </View> */}

                        <Text style={styles.orderItem}>下單項目 Ordering Item</Text>
                        <FlatList
                            // contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
                            data={this.state.order.products}
                            extraData={this.state}
                            renderItem={({ item }) =>
                                <View style={styles.list_border}>
                                    <View style={{ flexDirection: 'row', width: "100%" }}>
                                        <Text style={styles.leftorderItem}>{`貨品名稱Name : ` + item.name}</Text>
                                        <Text style={styles.rightlistedItem}>{`數量Qty : ` + item.qty}</Text>
                                    </View>
                                </View>
                            }
                            keyExtractor={item => item.product_id.toString()}
                        />
                        <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>收件人名稱 Receiver Name</Text>
                            <Text style={styles.rightlistedItem}>{this.state.receiver_name}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>收件人電話 Receiver Contact</Text>
                            <Text style={styles.rightlistedItem}>{this.state.receiver_contact}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>收件人電郵 Receiver Email</Text>
                            <Text style={styles.rightlistedItem}>{this.state.receiver_email}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>送貨地址 Delivery Address</Text>
                            <Text style={styles.rightlistedItem}>{this.state.delivery_address}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>運費 Delivery Cost</Text>
                            <Text style={styles.rightlistedItem}>{this.state.order.delivery_cost}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>送貨日期 Delivery Date</Text>
                            <Text style={styles.rightlistedItem}>{this.state.delivery_date}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>折扣 Discount</Text>
                            <Text style={styles.rightlistedItem}>{`HKD$` + this.state.order.discount}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>訂單總計 Total Price</Text>
                            <Text style={styles.rightlistedItem}>{`HKD$` + this.state.order.amount}</Text>
                        </View>
                        <FlatList
                            // contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
                            data={this.state.order.payments}
                            extraData={this.state}
                            renderItem={({ item }) =>
                                <View style={styles.list_border}>
                                    <View style={{ flexDirection: 'row', width: "100%" }}>
                                        <Text style={styles.leftlistedItem}>{`付款方式Payment Method : ` + item.payment_method}</Text>
                                        <Text style={styles.rightlistedItem}>{`付款狀態 : ` + item.status}</Text>
                                    </View>
                                    <FlatList
                                data={item.attachments}
                                extraData={this.state}
                                ItemSeparatorComponent={this.space}
                                renderItem={({ item }) =>
                                    <View>
                                        <a href={item.path} target='_blank'><Image source={{ uri: item.path }} style={styles.receipt} /></a>
                                    </View>
                                }
                                ListEmptyComponent={this._listEmptyComponent}
                            />
                                </View>
                                
                            }
                            keyExtractor={item => item.payment_id.toString()}
                        />

                        <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>訂單狀態 Order Status</Text>
                            <Text style={styles.rightlistedItem}>{this.state.order.status}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>備註 Remark</Text>
                            <Text style={styles.rightlistedItem}>{this.state.remark}</Text>
                        </View>
                        
                        {button}
                        
                        <Button
                            style={{ marginTop: 10 }}
                            large
                            title="完成 Finish"
                            onPress={this.onPressButton.bind(this)}
                        />
                    </View>
                </View>
            )
        } else if (this.state.is_edit) {
            return (
                <View style={styles.container}>
                    <Image style={styles.image} source={require('./image/hippos_banner.png')} />
                    <Text style={styles.title}>帳單詳情 Order Detail</Text>
                    <View style={styles.login_border}>
                        <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>帳單編號Order Number</Text>
                            <Text style={styles.rightlistedItem}>{this.state.order.order_number}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>下單店鋪 Ordering Shop</Text>
                            <Text style={styles.rightlistedItem}>{this.state.order.shop.name}</Text>
                        </View>
                        {this.state.had_member &&
                            <View style={{ flexDirection: 'row', width: "100%" }}>
                                <Text style={styles.leftlistedItem}>會員編號 Member No</Text>
                                <Text style={styles.rightlistedItem}>{this.state.order.member.member_no}</Text>
                            </View>
                        }
                        {this.state.had_member &&
                            <View style={{ flexDirection: 'row', width: "100%" }}>
                                <Text style={styles.leftlistedItem}>會員名稱 Member Nmae</Text>
                                <Text style={styles.rightlistedItem}>{this.state.order.member.name}</Text>
                            </View>
                        }
                        {this.state.had_member &&
                            <View style={{ flexDirection: 'row', width: "100%" }}>
                                <Text style={styles.leftlistedItem}>會員電話 Member Phone</Text>
                                <Text style={styles.rightlistedItem}>{this.state.order.member.phone}</Text>
                            </View>
                        }

                        {/* <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>下單員工 Ordering Staff</Text>
                            <Text style={styles.rightlistedItem}>{this.state.order.user_info.name}</Text>
                        </View> */}

                        <Text style={styles.orderItem}>下單項目 Ordering Item</Text>
                        <FlatList
                            // contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
                            data={this.state.order.products}
                            extraData={this.state}
                            renderItem={({ item }) =>
                                <View style={styles.list_border}>
                                    <View style={{ flexDirection: 'row', width: "100%" }}>
                                        <Text style={styles.leftorderItem}>{`貨品名稱Name : ` + item.name}</Text>
                                        <Text style={styles.rightlistedItem}>{`數量Qty : ` + item.qty}</Text>
                                    </View>
                                </View>
                            }
                            keyExtractor={item => item.product_id.toString()}
                        />
                        <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>收件人名稱 Receiver Name</Text>
                            <Text style={styles.rightlistedItem}>{this.state.receiver_name}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>收件人電話 Receiver Contact</Text>
                            <Text style={styles.rightlistedItem}>{this.state.receiver_contact}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>收件人電郵 Receiver Email</Text>
                            <Text style={styles.rightlistedItem}>{this.state.receiver_email}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>送貨地址 Delivery Address</Text>
                            <Text style={styles.rightlistedItem}>{this.state.delivery_address}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>運費 Delivery Cost</Text>
                            <Text style={styles.rightlistedItem}>{this.state.order.delivery_cost}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>送貨日期 Delivery Date</Text>
                            <Text style={styles.rightlistedItem}>{this.state.delivery_date}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>折扣 Discount</Text>
                            <Text style={styles.rightlistedItem}>{`HKD$` + this.state.order.discount}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>訂單總計 Total Price</Text>
                            <Text style={styles.rightlistedItem}>{`HKD$` + this.state.order.amount}</Text>
                        </View>
                        
                        <FlatList
                            // contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
                            data={this.state.order.payments}
                            extraData={this.state}
                            renderItem={({ item }) =>
                                <View style={styles.list_border}>
                                    <View style={{ flexDirection: 'row', width: "100%" }}>
                                        <Text style={styles.leftlistedItem}>{`付款方式Payment Method : ` + item.payment_method}</Text>
                                        {/* <Text style={styles.rightlistedItem}>{`付款狀態 : ` + item.status}</Text> */}
                                        <View style={{
                                            width: '50%', borderRadius: 50, borderColor: '#4189D6', borderWidth: 2, flex: 0.5
                                        }}>
                                            <SwitchSelector
                                                options={this.state.enable_options}
                                                initial={item.status=='paid'?1:item.status=='failed'?2:0}
                                                buttonColor={'#4189D6'}
                                                onPress={(value) => this.switchEnableSelected(value, item)}
                                            />
                                        </View>                                       
                                    </View> 
                                    
                                    <FlatList
                                data={item.attachments}
                                extraData={this.state}
                                ItemSeparatorComponent={this.space}
                                renderItem={({ item }) =>
                                    <View>
                                        <a href={item.path} target='_blank'><Image source={{ uri: item.path }} style={styles.receipt} /></a>
                                    </View>
                                }
                                ListEmptyComponent={this._listEmptyComponent}
                            />
                            
                                </View>
                                
                            }
                            
                            keyExtractor={item => item.payment_id.toString()}
                        />
                            
                        
                        <View style={styles.list_border}>
                            <View style={{ flexDirection: 'row', width: "100%" }}>
                                <Text style={styles.leftlistedItem}>{'訂單狀態 Order Status'}</Text>
                                <View style={{
                                    width: '50%', borderRadius: 50, borderColor: '#4189D6', borderWidth: 2, flex: 1
                                }}>
                                    <SwitchSelector
                                        options={this.state.order_options}
                                        initial={this.state.order_status}
                                        buttonColor={'#4189D6'}
                                        onPress={(value) => this.switchEnableStatusSelected(value)}
                                    />
                                </View>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>備註 Remark</Text>
                            <Text style={styles.rightlistedItem}>{this.state.remark}</Text>
                        </View>
                        <Button
                            style={{ marginTop: 10 }}
                            large
                            title="完成 Finish"
                            onPress={this.onUpdate.bind(this)}
                        />
                    </View>
                </View>
            )
        } else {
            return (
                <View style={[styles.container, styles.horizontal]}>
                    <ActivityIndicator size="large" color="#0000ff" />
                </View>
            )
        }
    }
}

async function getOrderDetail(uuid, token, email, order_id) {
    try {
        return fetch(path + 'sales/orderDetail', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            _body: JSON.stringify({
                version: '1',
                platform: 'ios',
                uuid: uuid,
                token: token,
                username: email,
                order_id: order_id,
                all_langs: 'yes',
            }),
            get body() {
                return this._body;
            },
            set body(value) {
                this._body = value;
            },
        })
            .then((response) => response.json())
        // return responseJson.data.payment_methods;
    }
    catch (error) {
        console.error(error);
    }
}

async function updatePaymentStatus(uuid, token, email, value, payment_id) {
    try {
        return fetch(path + 'sales/updatePaymentStatus', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            _body: JSON.stringify({
                version: '1',
                platform: 'ios',
                uuid: uuid,
                token: token,
                username: email,
                payment_id: payment_id,
                status: value,
                all_langs: 'yes',
            }),
            get body() {
                return this._body;
            },
            set body(value) {
                this._body = value;
            },
        })
            .then((response) => response.json())
        // return responseJson.data.payment_methods;
    }
    catch (error) {
        console.error(error);
    }
}

async function updateOrderStatus(uuid, token, email, value, order_id) {
    try {
        return fetch(path + 'sales/updateOrderStatus', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            _body: JSON.stringify({
                version: '1',
                platform: 'ios',
                uuid: uuid,
                token: token,
                username: email,
                order_id: order_id,
                status: value,
            }),
            get body() {
                return this._body;
            },
            set body(value) {
                this._body = value;
            },
        })
            .then((response) => response.json())
        // return responseJson.data.payment_methods;
    }
    catch (error) {
        console.error(error);
    }
}



const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 10
    },
    listedItem: {
        fontSize: 20,
        margin: 5,
    },
    title: {
        marginTop: 10,
        fontSize: 30,
    },
    input: {
        fontSize: 20,
        margin: 5,
        marginBottom: 10,
        borderWidth: 2,  // size/width of the border
        borderColor: 'lightgrey',  // color of the border
        height: 30
    },
    inputArea: {
        fontSize: 20,
        margin: 5,
        marginBottom: 10,
        borderWidth: 2,  // size/width of the border
        borderColor: 'lightgrey',  // color of the border
    },
    login_border: {
        marginTop: 10,
        width: '70%',
        borderColor: '#000',
        borderRadius: 5,
        borderWidth: 2,
        padding: 10,
    },
    image: {
        padding: 10, alignSelf: 'center',
        resizeMode: "stretch",
        flex: 1,
        width: '50%',
        maxWidth: '100%',
        height: 200,
    },
    receipt: {
        padding: 10, alignSelf: 'center',
        resizeMode: "contain",
        flex: 1,
        width: '50%',
        maxWidth: '100%',
        height: 200,
    },
    leftorderItem: {
        flex: 0.7,
        textAlign: 'start',
        fontSize: 16,
        // background: '#285b97',
        borderRadius: 3,
        marginLeft: 5,
        marginTop: 5,
    },
    leftlistedItem: {
        flex: 0.7,
        textAlign: 'start',
        fontSize: 16,
        fontWeight: "bold",
        // background: '#285b97',
        borderRadius: 3,
        marginLeft: 5,
        marginTop: 5,
    },
    rightlistedItem: {
        flex: 0.3,
        textAlign: 'end',
        fontSize: 16,
        // background: '#285b97',
        borderRadius: 3,
        marginLeft: 5,
        marginTop: 5,
    }, orderItem: {
        fontSize: 16,
        // background: '#285b97',
        fontWeight: "bold",
        borderRadius: 3,
        marginLeft: 5,
        textDecorationLine: 'underline',
        marginTop: 5,
    },
});

export default OrderDetail;