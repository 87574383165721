import React, { Component } from 'react';
import { Image, StyleSheet, Alert, View, TextInput, Text, Platform } from 'react-native';
import { ThemeProvider, Button } from 'react-native-elements';
import { AsyncStorage } from 'react-native';
import { Redirect } from 'react-router';
import path from './serverPath.js'
import SwitchSelector from 'react-native-switch-selector';

import ImagePicker from "react-native-everywhere-image-picker";

class CreateBanner extends Component {

    constructor(props) {
        super(props);
        this.state = {
            image: '',
            previewImage: '',
            url: '',
            ordering: '',

            new_page: 'no',
            switch_position: 0,
            switch_options: [],

            enable: 'no',
            enable_position: 0,
            enable_options: [],
        };
    }

    async onPressButton() {
        const uuid = await AsyncStorage.getItem('uuid');
        const token = await AsyncStorage.getItem('token');
        const user_info = await AsyncStorage.getItem('user_info');
        const userInfo = JSON.parse(user_info);

        createBanner(userInfo.email, uuid, token, this.state.image, this.state.new_page, this.state.ordering, this.state.url).then(data => {
            console.log(data)
            if (data.result == 0) {
                this.props.history.push('/selectCompanyAction')
            }
        });
    }


    async componentDidMount() {

        const switch_options = [
            { label: "否No", value: 'no' },
            { label: "是Yes", value: 'yes' },
        ];

        const enable_options = [
            { label: "停用Disable", value: 'no' },
            { label: "啟用Enable", value: 'yes' },
        ];

        this.setState({
            switch_options,
            enable_options
        })
    }

    onPressBack = () => {
        this.props.history.push('/selectCompanyAction');
    }

    _handleImage = ({ uri }) => {

        var startIndex = uri.indexOf("base64,") + 7;
        var b64 = uri.substr(startIndex);
        var byteCharacters = atob(b64);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var image = new Blob([byteArray], { type: 'image/png' });

        this.setState(() => ({ image, previewImage: uri }));

    };

    switchSelected(value) {
        if (value == 'no') {
            this.setState({ new_page: 'no', switch_position: 0 })
        } else {
            this.setState({ new_page: 'yes', switch_position: 1 })
        }
    }

    switchEnableSelected(value) {
        if (value == 'no') {
            this.setState({ enable: 'no', enable_position: 0 })
        } else {
            this.setState({ enable: 'yes', enable_position: 1 })
        }
    }

    handleRemoveImage() {
        this.setState({
            image: '', previewImage: ''
        })
    }

    render() {
        return (
            <View style={styles.container}>
                <Image style={styles.banner} source={require('./image/hippos_banner.png')} />
                <Text style={styles.title}>新增橫幅 Create Banner</Text>
                <View style={styles.login_border}>
                    <Text style={styles.text}>新一頁 New Page</Text>
                    <View style={{ width: '30%', borderRadius: 50, borderColor: '#4189D6', borderWidth: 2 }}>
                        <SwitchSelector
                            options={this.state.switch_options}
                            initial={this.state.switch_position}
                            buttonColor={'#4189D6'}
                            onPress={(value) => this.switchSelected(value)}
                        />
                    </View>

                    <View>
                        <Text style={styles.text}>網頁連結 URL</Text>
                        <TextInput
                            style={styles.inputfield}
                            placeholder="網頁連結 URL"
                            onChangeText={(text) => { this.setState({ url: text }) }}
                            value={`${this.state.url}`}
                        />
                    </View>


                    <Text style={styles.text}>啟用Enable/停用Disable</Text>
                    <View style={{ width: '30%', borderRadius: 50, borderColor: '#4189D6', borderWidth: 2 }}>
                        <SwitchSelector
                            options={this.state.enable_options}
                            initial={this.state.enable_position}
                            buttonColor={'#4189D6'}
                            onPress={(value) => this.switchEnableSelected(value)}
                        />
                    </View>

                    <View>
                        <Text style={styles.text}>次序 Ordering</Text>
                        <TextInput
                            style={styles.inputfield}
                            placeholder="e.g. 1/2/3/4, 數字愈細愈前"
                            onChangeText={(text) => { this.setState({ ordering: text }) }}
                            value={`${this.state.ordering}`}
                        />
                    </View>

                    <ImagePicker onComplete={this._handleImage}>
                        <Button
                            style={{ marginTop: 20, marginBottom: 20 }}
                            large
                            title="選擇圖片Select Image"
                        />
                    </ImagePicker>

                    {this.state.previewImage != '' &&
                        <Image source={{ uri: this.state.previewImage }} style={styles.image} />}
                    {this.state.previewImage != '' &&
                        <ThemeProvider theme={theme} >
                            <Button title='刪除 Remove'
                                onPress={() => this.handleRemoveImage(this)} />
                        </ThemeProvider>}

                    <Button
                        large
                        title="創建 Create"
                        onPress={this.onPressButton.bind(this)}
                    />
                    <Button
                        style={{ marginTop: 10 }}
                        large
                        title="返回 Back"
                        onPress={this.onPressBack.bind(this)}
                    />

                </View>

            </View>
        );
    }
}

async function createBanner(email, uuid, token, image, new_page, ordering, url) {

    const data = new FormData();

    data.append('username', email);
    data.append('uuid', uuid);
    data.append('token', token);
    data.append('enable', 'yes');
    data.append('new_page', new_page);
    data.append('image', image);
    if (ordering == '') {
        data.append('ordering', '1');
    } else {
        data.append('ordering', ordering);
    }
    data.append('url', url);

    try {
        return fetch(path + 'createBanner', {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            _body: data,
            get body() {
                return this._body;
            },
            set body(value) {
                this._body = value;
            },
        })
            .then((response) => response.json())
    }
    catch (error) {
        console.error(error);
    }
}


const theme = {
    colors: {
        primary: 'red',
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    listedItem: {
        fontSize: 20,
        margin: 5,
    },
    title: {
        marginTop: 10,
        fontSize: 30,
    },
    input: {
        fontSize: 20,
        margin: 5,
        marginBottom: 10,
        borderWidth: 2,  // size/width of the border
        borderColor: 'lightgrey',  // color of the border
        height: 30
    },
    login_border: {
        marginTop: 10,
        width: '70%',
        borderColor: '#000',
        borderRadius: 5,
        borderWidth: 2,
        padding: 10,
    },
    image: {
        padding: 10,
        alignSelf: 'center',
        resizeMode: 'contain',
        width: '100%',
        height: 200,
    },
    banner: {
        padding: 10, alignSelf: 'center',
        resizeMode: "contain",
        flex: 1,
        width: '50%',
        maxWidth: '100%',
        height: 200,
    },
    text: {
        marginTop: 10,
        marginBottom: 2,
        fontSize: 20,
    },
    inputfield: {
        borderRadius: 8,
        borderWidth: 2,
        color: 'black',
        borderColor: '#4189D6',
        paddingLeft: 10,
        fontSize: 20,
        height: 40
    },
});

export default CreateBanner;