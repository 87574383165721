import React, { Component } from 'react';
import { Image, StyleSheet, Alert, View, TextInput, Text, Platform } from 'react-native';
import { Button } from 'react-native-elements';
import { AsyncStorage } from 'react-native';
import { Redirect } from 'react-router';
import path from './serverPath.js'

class EditStockIn extends Component {

    constructor(props) {
        super(props);
        this.state = {
            product: null,
            stock: null,
            uuid: '',
            token: '',
            user_info: '',
            product_id: '',
            product_name: '',
            batch_number: '',
            cost: '',
            qty: '',
            shop_id: '',
            stock_id: '',
            stockOut_qty: '',
            remark: '',
            description: '',
            type: '',
        };
    }

    async onPressButton() {
        const userInfo = JSON.parse(this.state.user_info);

        stockOut(userInfo.email, this.state.uuid, this.state.token, this.state.stock_id, this.state.cost).then(data => {
            console.log(data)
            if (data.result == 0) {
                this.props.history.push('/stockInRecord', this.state.product);
            }
        });
    }

    onPressBack = () => {
        this.props.history.push('/stockInRecord', this.state.product);
    }

    async componentDidMount() {
        const stock = this.props.location.state.stock
        const product = this.props.location.state.product
        const uuid = await AsyncStorage.getItem('uuid');
        const token = await AsyncStorage.getItem('token');
        const user_info = await AsyncStorage.getItem('user_info');

        console.log(this.props.location.state)

        this.setState({
            product,
            stock, uuid, token, user_info,
            product_id: stock.product_id,
            product_name: stock.product_name,
            batch_number: stock.batch_number,
            cost: stock.cost,
            stock_id: stock.id,
            shop_id: stock.shop_id,
            description: stock.description,
            remaining_qty: stock.remaining_qty,
            remark: stock.remark,
            type: stock.type,
            qty: stock.qty
        })
    }

    render() {
        return (
            <View style={styles.container}>
                <Image style={styles.image} source={require('./image/hippos_banner.png')} />
                <Text style={styles.title}>存貨出庫 Stock Out</Text>
                <View style={styles.login_border}>
                    <Text style={styles.listedItem}>
                        貨品名稱Product Name(EN) : {this.state.product_name}</Text>
                    <Text style={styles.listedItem}>{`批次編號Batch Number : ` + this.state.batch_number}</Text>
                    <Text style={styles.listedItem}>{`說明Description : ` + this.state.description}</Text>
                    <Text style={styles.listedItem}>成本 Cost : </Text>
                    <TextInput
                        style={styles.input}
                        placeholder="成本 Cost"
                        onChangeText={(text) => { this.setState({ cost: text }) }}
                        value={`${this.state.cost}`}
                    />
                    <Text style={styles.listedItem}>{`數量 Qty : ` + this.state.qty}</Text>
                    <Text style={styles.listedItem}>{`剩餘數量 Remain Qty : ` + this.state.remaining_qty}</Text>
                    <Button
                        large
                        title="更新 Update"
                        onPress={this.onPressButton.bind(this)}
                    />
                    <Button
                        style={{ marginTop: 10 }}
                        large
                        title="返回 Back"
                        onPress={this.onPressBack.bind(this)}
                    />

                </View>

            </View>
        );
    }
}

async function stockOut(email, uuid, token, stock_id, cost) {

    const data = new FormData();

    data.append('username', email)
    data.append('uuid', uuid);
    data.append('token', token);
    data.append('stock_id', stock_id)
    data.append('cost', cost)


    try {
        return fetch(path + 'inventory/stockUpdate', {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            _body: data,
            get body() {
                return this._body;
            },
            set body(value) {
                this._body = value;
            },
        })
            .then((response) => response.json())
        // return responseJson.data.payment_methods;
    }
    catch (error) {
        console.error(error);
    }
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    listedItem: {
        fontSize: 20,
        margin: 5,
    },
    title: {
        marginTop: 10,
        fontSize: 30,
    },
    input: {
        fontSize: 20,
        margin: 5,
        marginBottom: 10,
        borderWidth: 2,  // size/width of the border
        borderColor: 'lightgrey',  // color of the border
        height: 30
    },
    inputArea: {
        fontSize: 20,
        margin: 5,
        marginBottom: 10,
        borderWidth: 2,  // size/width of the border
        borderColor: 'lightgrey',  // color of the border

    },
    login_border: {
        marginTop: 10,
        width: '70%',
        borderColor: '#000',
        borderRadius: 5,
        borderWidth: 2,
        padding: 10,
    },
    image: {
        padding: 10, alignSelf: 'center',
        resizeMode: "stretch",
        flex: 1,
        width: '50%',
        maxWidth: '100%',
        height: 200,
    },
});

export default EditStockIn;