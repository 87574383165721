import React, { Component } from 'react';
import { FlatList, Image, StyleSheet, Alert, View, TextInput, Text, Switch } from 'react-native';
import { Button, ThemeProvider, ThemeConsumer, ButtonGroup } from 'react-native-elements';
import Icon from 'react-native-vector-icons/FontAwesome';
import { AsyncStorage } from 'react-native';
import { Redirect } from 'react-router';
import RNPickerSelect from 'react-native-picker-select';
import CheckboxFormX from 'react-native-checkbox-form';
import ImagePicker from "react-native-everywhere-image-picker";
import path from './serverPath.js'
import SwitchSelector from 'react-native-switch-selector';

class EditProduct extends Component {

    //   handlePress = payment_methods => {
    //     this.props.history.push('/showPayment', payment_methods);
    //   };

    constructor(props) {
        super(props);
        this.state = {
            warning_shop: false,

            product: null,
            product_id: '',
            image_uri: '',
            sku: '',
            price: '',
            name_en: '',
            name_zh_TW: '',
            name_zh_CN: '',
            description_en: '',
            description_zh_TW: '',
            description_zh_CN: '',
            packing_en: '',
            packing_zh_TW: '',
            packing_zh_CN: '',
            short_description_en: '',
            short_description_zh_TW: '',
            short_description_zh_CN: '',
            bar_code: '',
            ordering: '',
            //keywords: '',
            keywords_en: '',
            keywords_zh_TW: '',
            keywords_zh_CN: '',
            categoryItems: null,
            categoryIDs: null,
            shopItems: null,
            shopIDs: null,
            show: false,
            remove_image_ids: [],
            imageIDs: [],
            images: [],
            previewImage: [],
            isEnabled: '',
            switch_position: 0,
            switch_options: [],

            //Brand
            brands: null,
            brandItems: null,
            brandID: '',

            //ButtonGroup
            selectedIndex: 0,
            buttonItem: [],

            is_english: true,
            is_traditional: false,
            is_simplified: false,

            default_lang: '',
        };
    }

    async onPressButton() {
        const uuid = await AsyncStorage.getItem('uuid');
        const token = await AsyncStorage.getItem('token');
        const user_info = await AsyncStorage.getItem('user_info');
        const userInfo = JSON.parse(user_info);


        const keyword_en = this.state.keywords_en
        const keyword_en_withno_space = keyword_en.toString().replace(/\s/g, '');
        const keywords_en = keyword_en_withno_space.split(',')

        const keyword_zh_TW = this.state.keywords_zh_TW
        const keyword_zh_TW_withno_space = keyword_zh_TW.toString().replace(/\s/g, '');
        const keywords_zh_TW = keyword_zh_TW_withno_space.split(',')

        const keyword_zh_CN = this.state.keywords_zh_CN
        const keyword_zh_CN_withno_space = keyword_zh_CN.toString().replace(/\s/g, '');
        const keywords_zh_CN = keyword_zh_CN_withno_space.split(',')

        if (this.state.shopIDs.length > 0) {
            createProduct(this.state.product_id, userInfo.email, uuid, this.state.shopIDs, token, this.state.sku, this.state.price, this.state.isEnabled,
                this.state.name_en, this.state.name_zh_TW, this.state.name_zh_CN,
                this.state.description_en, this.state.description_zh_TW, this.state.description_zh_CN,
                this.state.packing_en, this.state.packing_zh_TW, this.state.packing_zh_CN,
                this.state.short_description_en, this.state.short_description_zh_TW, this.state.short_description_zh_CN,
                this.state.categoryIDs, this.state.bar_code, '', keywords_en, keywords_zh_TW, keywords_zh_CN, this.state.images, this.state.remove_image_ids, this.state.ordering, this.state.brandID).then(data => {
                    console.log(data)
                    this.props.history.push('/productlist');
                });
        }

        if (this.state.shopIDs == 0) {
            this.setState({ warning_shop: true })
        }
    }


    _onCategorySelect = (items) => {
        var categoryIDs = [];
        for (let item of items) {
            if (item.RNchecked) {
                categoryIDs.push(item.value);
            }
        }
        this.setState({
            categoryIDs
        })
    };

    _onShopSelect = (items) => {
        var shopIDs = [];
        for (let item of items) {
            if (item.RNchecked) {
                shopIDs.push(item.value);
            }
        }
        this.setState({
            shopIDs
        })
    };

    onSelect(value) {
        console.log(value)
        if (value.includes("Select")) {
            this.setState({
                brandID: ''
            })
        } else {
            this.setState({
                brandID: value
            })
        }
    }

    _handleImage = ({ uri }) => {

        var startIndex = uri.indexOf("base64,") + 7;
        var b64 = uri.substr(startIndex);
        var byteCharacters = atob(b64);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var data = new Blob([byteArray], { type: 'image/png' });
        var images = []
        var previewImage = []
        this.state.images.forEach((image) => {
            images.push(image);
        });
        this.state.previewImage.forEach((image) => {
            previewImage.push(image)
        });
        images.push(data);
        previewImage.push(uri);
        this.setState(() => ({ images, previewImage }));

    };

    async componentDidMount() {

        const uuid = await AsyncStorage.getItem('uuid');
        const token = await AsyncStorage.getItem('token');
        const user_info = await AsyncStorage.getItem('user_info');
        const userInfo = JSON.parse(user_info);

        this.setState({ product: this.props.location.state });

        const categories = JSON.parse(await AsyncStorage.getItem('categories'));

        const categiriesArray = this.props.location.state.categories;

        var categriesIDs = [];
        for (let category of categiriesArray) {
            categriesIDs.push(category.id)
        }

        var categoryItems = [];

        for (let category_items of categories) {
            categoryItems.push({ label: category_items.name, value: category_items.id, RNchecked: false });
        }

        for (let categriesID of categriesIDs) {
            for (var i = 0; i < categoryItems.length; i++) {
                if (categoryItems[i].value == categriesID) {
                    categoryItems[i].RNchecked = true;
                }
            }
        }

        const shops = JSON.parse(await AsyncStorage.getItem('shops'))

        const shopArray = this.props.location.state.shops;

        var shopIDs = [];
        for (let shop of shopArray) {
            shopIDs.push(shop.id)
        }

        var shopItems = [];

        for (let shop_items of shops) {
            shopItems.push({ label: shop_items.name, value: shop_items.shop_id })
        }

        for (let shopID of shopIDs) {
            for (var i = 0; i < shopItems.length; i++) {
                if (shopItems[i].value == shopID) {
                    shopItems[i].RNchecked = true;
                }
            }
        }

        // var keywordsArray = this.props.location.state.keywords

        // var keywordNames = []

        // for (let keyword of keywordsArray) {
        //     keywordNames.push(keyword.name_en)
        // }

        var keywordsArray_en = this.props.location.state.keywords_en

        var keywordNames_en = []

        for (let keyword_en of keywordsArray_en) {
            keywordNames_en.push(keyword_en.name)
            console.log(keyword_en.name)
        }

        var keywordsArray_zh_TW = this.props.location.state.keywords_zh_TW

        var keywordNames_zh_TW = []

        for (let keyword_zh_TW of keywordsArray_zh_TW) {
            keywordNames_zh_TW.push(keyword_zh_TW.name)
            console.log(keyword_zh_TW.name)
        }

        var keywordsArray_zh_CN = this.props.location.state.keywords_zh_CN

        var keywordNames_zh_CN = []

        for (let keyword_zh_CN of keywordsArray_zh_CN) {
            keywordNames_zh_CN.push(keyword_zh_CN.name)
        }


        var imagesArray = this.props.location.state.images

        var imagePaths = []
        var imageIDs = []

        for (let image of imagesArray) {
            imagePaths.push(image.path)
            imageIDs.push(image.id)
        }

        const switch_options = [
            { label: "下架Disable", value: 'no' },
            { label: "上架Active", value: 'yes' },
        ];

        var isEnabled = ''
        var switch_position = 0

        if (this.props.location.state.enable === 'yes') {
            isEnabled = 'yes'
            switch_position = 1
        } else if (this.props.location.state.enable === 'no') {
            isEnabled = 'no'
            switch_position = 0
        }

        const buttonItem = ['英文English', '繁體中文Traditional', '简体中文Simplified']

        var brandID = ''
        if(this.props.location.state.brand!=null){
            brandID = this.props.location.state.brand.id
        }

        const default_lang = await AsyncStorage.getItem('default_lang')

        var is_english = false;
        var is_traditional = false
        var is_simplified = false

        var selectedIndex = 0

        if (default_lang == "en") {
            selectedIndex = 0
            is_english = true
            is_traditional = false
            is_simplified = false
        } else if (default_lang == "zh_TW") {
            selectedIndex = 1
            is_english = false
            is_traditional = true
            is_simplified = false
        } else {
            selectedIndex = 2
            is_english = false
            is_traditional = false
            is_simplified = true
        }

        this.setState({
            buttonItem,
            product_id: this.props.location.state.id,
            categoryItems,
            shopItems,
            isEnabled,
            switch_position,
            switch_options,
            show: true,
            sku: this.props.location.state.sku,
            price: this.props.location.state.price,
            name_en: this.props.location.state.name_en,
            name_zh_TW: this.props.location.state.name_zh_TW,
            name_zh_CN: this.props.location.state.name_zh_CN,
            description_en: this.props.location.state.description_en,
            description_zh_TW: this.props.location.state.description_zh_TW,
            description_zh_CN: this.props.location.state.description_zh_CN,
            packing_en: this.props.location.state.packing_en,
            packing_zh_TW: this.props.location.state.packing_zh_TW,
            packing_zh_CN: this.props.location.state.packing_zh_CN,
            short_description_en: this.props.location.state.short_description_en,
            short_description_zh_TW: this.props.location.state.short_description_zh_TW,
            short_description_zh_CN: this.props.location.state.short_description_zh_CN,
            bar_code: this.props.location.state.bar_code,
            //keywords: keywordNames,
            keywords_en: keywordNames_en,
            keywords_zh_TW: keywordNames_zh_TW,
            keywords_zh_CN: keywordNames_zh_CN,
            imageIDs: imageIDs,
            categoryIDs: categriesIDs,
            shopIDs,
            brandID,
            // images: imagePaths,
            default_lang,
            selectedIndex,
            is_english,
            is_traditional,
            is_simplified,
            previewImage: imagePaths,
            ordering: this.props.location.state.ordering,
        })

        getBrands(uuid, token, userInfo.email).then(data => {
            console.log(data)
            var brands = data.data.brands

            var brandItems = []
            for (let brand of brands) {
                brandItems.push({ label: brand.name_en, value: brand.id })
            }

            console.log(brandItems)
            this.setState({
                brands,

                show: true,
                brandItems,
            })
        })
    }

    _listEmptyComponent = () => {
        return (
            <View>

            </View>
        )
    }

    onBack = () => {
        this.props.history.push('/productList');
    }

    onStockIn = () => {
        this.props.history.push('/stockIn', this.state.product);
    }

    onStockInRecord = () => {
        this.props.history.push('/stockInRecord', this.state.product);
    }

    onStockOut = () => {
        this.props.history.push('/stockOutList', this.state.product);
    }

    handleRemoveImage(index) {
        var imageIDs = this.state.imageIDs
        var images = this.state.images
        var previewImages = this.state.previewImage
        var remove_image_ids = this.state.remove_image_ids

        var previewImage = previewImages[index];

        if (previewImage.includes('hippos')) {
            remove_image_ids.push(imageIDs[index])
            imageIDs.splice(index, 1);
            previewImages.splice(index, 1);

            this.setState({
                remove_image_ids,
                imageIDs,
                previewImage: previewImages
            })
        } else {
            images.splice(index, 1);
            previewImages.splice(index, 1);

            this.setState({
                images,
                previewImage: previewImages
            })
        }
    }

    switchSelected(value) {
        if (value == 'no') {
            this.setState({ isEnabled: 'no', switch_position: 0 })
        } else {
            this.setState({ isEnabled: 'yes', switch_position: 1 })
        }
    }

    updateIndex(selectedIndex) {
        if (selectedIndex == 0) {
            this.setState({
                selectedIndex,
                is_english: true,
                is_traditional: false,
                is_simplified: false
            })
        } else if (selectedIndex == 1) {
            this.setState({
                selectedIndex,
                is_english: false,
                is_traditional: true,
                is_simplified: false
            })
        } else {
            this.setState({
                selectedIndex,
                is_english: false,
                is_traditional: false,
                is_simplified: true
            })
        }
    }

    render() {
        if (this.state.show && this.state.brands != null) {
            return (
                <View>
                    <View style={{ flexDirection: 'row', width: "100%" }}>
                        <Image style={styles.bannner} source={require('./image/hippos_banner.png')} />
                        <View style={{ marginLeft: 20, padding: 10, flexDirection: 'column-reverse' }}>
                            <View style={{
                                // alignItems: "center",
                                width: '100%',
                                marginTop: 10,
                                marginBottom: 10
                            }}>
                                <Button
                                    style={{
                                        marginTop: 10,
                                        marginBottom: 10
                                    }}
                                    title="返回 Back"
                                    onPress={this.onBack.bind(this)}
                                />
                                <Button
                                    title="存貨入庫 Stock In"
                                    onPress={this.onStockIn.bind(this)}
                                />
                                <Button
                                    style={{
                                        marginTop: 10,
                                    }}
                                    title="入庫記錄 Stock In Record"
                                    onPress={this.onStockInRecord.bind(this)}
                                />
                                <Button
                                    style={{
                                        marginTop: 10,
                                        marginBottom: 10
                                    }}
                                    title="存貨出庫 Stock Out"
                                    onPress={this.onStockOut.bind(this)}
                                />
                            </View>
                        </View>
                    </View>

                    <Text style={styles.title}>編輯貨品Edit Product</Text>
                    <View style={styles.border}>

                        {this.state.warning_shop &&
                            <Text style={styles.warning_item}>請選擇店鋪Please Select Shop</Text>}

                        <Text style={styles.text}>店鋪Shop</Text>
                        <CheckboxFormX
                            style={{ width: '100%' }}
                            textStyle={{ marginRight: 20, fontSize: 20 }}
                            dataSource={this.state.shopItems}
                            itemShowKey="label"
                            itemCheckedKey="RNchecked"
                            iconSize={30}
                            formHorizontal={true}
                            labelHorizontal={true}
                            onChecked={(item) => this._onShopSelect(item)}
                        />

                        <Text style={styles.text}>品牌Brand</Text>
                        <RNPickerSelect
                            style={{ ...pickerSelectStyles }}
                            value={this.state.brandID}
                            onValueChange={this.onSelect.bind(this)}
                            // onValueChange={(value) => console.log(value)}
                            items={this.state.brandItems}
                        />

                        <Text style={styles.text}>SKU</Text>
                        <TextInput
                            style={styles.inputfield}
                            textContentType="text"
                            placeholder="SKU"
                            onChangeText={(text) => { this.setState({ sku: text }) }}
                            value={`${this.state.sku}`}
                        />
                        <Text style={styles.text}>價錢Price</Text>
                        <TextInput
                            style={styles.inputfield}
                            textContentType="number"
                            placeholder="價錢Price"
                            onChangeText={(text) => { this.setState({ price: text }) }}
                            value={`${this.state.price}`}
                        />
                        <Text style={styles.text}>上下架Active/Disable</Text>
                        <View style={{ width: '30%', borderRadius: 50, borderColor: '#4189D6', borderWidth: 2 }}>
                            <SwitchSelector
                                options={this.state.switch_options}
                                initial={this.state.switch_position}
                                buttonColor={'#4189D6'}
                                onPress={(value) => this.switchSelected(value)}
                            />
                        </View>

                        <Text style={styles.text}>分類Category</Text>
                        <CheckboxFormX
                            style={{ width: '100%' }}
                            textStyle={{ marginRight: 20, fontSize: 20 }}
                            dataSource={this.state.categoryItems}
                            itemShowKey="label"
                            itemCheckedKey="RNchecked"
                            iconSize={30}
                            formHorizontal={true}
                            labelHorizontal={true}
                            onChecked={(item) => this._onCategorySelect(item)}
                        />
                        <Text style={styles.text}>條碼Barcode</Text>
                        <TextInput
                            style={styles.inputfield}
                            textContentType="text"
                            placeholder="條碼Barcode"
                            onChangeText={(text) => { this.setState({ bar_code: text }) }}
                            value={`${this.state.bar_code}`}
                        />

                        <View>
                            <Text style={styles.text}>次序 Ordering</Text>
                            <TextInput
                                style={styles.inputfield}
                                placeholder="e.g. 1/2/3/4, 數字愈大愈前"
                                onChangeText={(text) => { this.setState({ ordering: text }) }}
                                value={`${this.state.ordering}`}
                            />
                        </View>

                        <View style={{ alignItems: 'center' }}>
                            <Text style={styles.text}>語言Language</Text>
                            <ButtonGroup
                                onPress={this.updateIndex.bind(this)}
                                selectedIndex={this.state.selectedIndex}
                                buttons={this.state.buttonItem}
                                containerStyle={{ height: 40, width: '50%', justifyContent: 'center', alignContent: 'center' }}
                            />
                        </View>

                        {this.state.is_english &&

                            <View>
                                <Text style={styles.text}>名稱Name(EN)</Text>
                                <TextInput
                                    style={styles.inputfield}
                                    textContentType="text"
                                    placeholder="名稱Name(EN)"
                                    onChangeText={(text) => { this.setState({ name_en: text }) }}
                                    value={`${this.state.name_en}`}
                                />
                                <Text style={styles.text}>包裝Packing(EN)</Text>
                                <TextInput
                                    style={styles.inputfield}
                                    textContentType="text"
                                    placeholder="包裝Packing(EN)"
                                    onChangeText={(text) => { this.setState({ packing_en: text }) }}
                                    value={`${this.state.packing_en}`}
                                />
                                <Text style={styles.text}>介紹Description(EN)</Text>
                                <TextInput
                                    style={styles.inputfield}
                                    textContentType="text"
                                    placeholder="介紹Description(EN)"
                                    onChangeText={(text) => { this.setState({ description_en: text }) }}
                                    value={`${this.state.description_en}`}
                                />
                                <Text style={styles.text}>簡介Short Description(EN)</Text>
                                <TextInput
                                    style={styles.inputfield}
                                    textContentType="text"
                                    placeholder="簡介Short Description(EN)"
                                    onChangeText={(text) => { this.setState({ short_description_en: text }) }}
                                    value={`${this.state.short_description_en}`}
                                />
                                <Text style={styles.text}>Keywords</Text>
                                <TextInput
                                    style={styles.inputfield}
                                    textContentType="text"
                                    placeholder="Keywords : 食物, Food, etc.."
                                    onChangeText={(text) => { this.setState({ keywords_en: text }) }}
                                    value={`${this.state.keywords_en}`}
                                />
                            </View>
                        }
                        {this.state.is_traditional &&
                            <View>
                                <Text style={styles.text}>名稱Name(繁體)</Text>
                                <TextInput
                                    style={styles.inputfield}
                                    textContentType="text"
                                    placeholder="名稱Name(繁體)"
                                    onChangeText={(text) => { this.setState({ name_zh_TW: text }) }}
                                    value={`${this.state.name_zh_TW}`}
                                />
                                <Text style={styles.text}>包裝Packing(繁體)</Text>
                                <TextInput
                                    style={styles.inputfield}
                                    textContentType="text"
                                    placeholder="包裝Packing(繁體)"
                                    onChangeText={(text) => { this.setState({ packing_zh_TW: text }) }}
                                    value={`${this.state.packing_zh_TW}`}
                                />
                                <Text style={styles.text}>介紹Description(繁體)</Text>
                                <TextInput
                                    style={styles.inputfield}
                                    textContentType="text"
                                    placeholder="介紹Description(繁體)"
                                    onChangeText={(text) => { this.setState({ description_zh_TW: text }) }}
                                    value={`${this.state.description_zh_TW}`}
                                />
                                <Text style={styles.text}>簡介Short Description(繁體)</Text>
                                <TextInput
                                    style={styles.inputfield}
                                    textContentType="text"
                                    placeholder="簡介Short Description(繁體)"
                                    onChangeText={(text) => { this.setState({ short_description_zh_TW: text }) }}
                                    value={`${this.state.short_description_zh_TW}`}
                                />
                                <Text style={styles.text}>關鍵字Keywords(繁體)</Text>
                                <TextInput
                                    style={styles.inputfield}
                                    textContentType="text"
                                    placeholder="關鍵字Keywords : 食物, Food, etc.."
                                    onChangeText={(text) => { this.setState({ keywords_zh_TW: text }) }}
                                    value={`${this.state.keywords_zh_TW}`}
                                />
                            </View>
                        }
                        {this.state.is_simplified &&
                            <View>
                                <Text style={styles.text}>名稱Name(簡體)</Text>
                                <TextInput
                                    style={styles.inputfield}
                                    textContentType="text"
                                    placeholder="名稱Name(簡體)"
                                    onChangeText={(text) => { this.setState({ name_zh_CN: text }) }}
                                    value={`${this.state.name_zh_CN}`}
                                />
                                <Text style={styles.text}>包裝Packing(簡體)</Text>
                                <TextInput
                                    style={styles.inputfield}
                                    textContentType="text"
                                    placeholder="包裝Packing(簡體)"
                                    onChangeText={(text) => { this.setState({ packing_zh_CN: text }) }}
                                    value={`${this.state.packing_zh_CN}`}
                                />
                                <Text style={styles.text}>介紹Description(簡體)</Text>
                                <TextInput
                                    style={styles.inputfield}
                                    textContentType="text"
                                    placeholder="介紹Description(簡體)"
                                    onChangeText={(text) => { this.setState({ description_zh_CN: text }) }}
                                    value={`${this.state.description_zh_CN}`}
                                />
                                <Text style={styles.text}>簡介Short Description(簡體)</Text>
                                <TextInput
                                    style={styles.inputfield}
                                    textContentType="text"
                                    placeholder="簡介Short Description(簡體)"
                                    onChangeText={(text) => { this.setState({ short_description_zh_CN: text }) }}
                                    value={`${this.state.short_description_zh_CN}`}
                                />
                                <Text style={styles.text}>關鍵字Keywords(簡體)</Text>
                                <TextInput
                                    style={styles.inputfield}
                                    textContentType="text"
                                    placeholder="關鍵字Keywords : 食物, Food, etc.."
                                    onChangeText={(text) => { this.setState({ keywords_zh_CN: text }) }}
                                    value={`${this.state.keywords_zh_CN}`}
                                />
                            </View>
                        }

                        <ImagePicker onComplete={this._handleImage}>
                            <Button
                                style={{ marginTop: 20, marginBottom: 20 }}
                                large
                                title="選擇圖片Select Image"
                            />
                        </ImagePicker>


                        <FlatList
                            data={this.state.previewImage}
                            extraData={this.state}
                            ItemSeparatorComponent={this.space}
                            renderItem={({ item, index }) =>
                                <View>
                                    <Image source={{ uri: item }} style={styles.image} />
                                    <ThemeProvider theme={theme} >
                                        <Button title='Remove'
                                            onPress={() => this.handleRemoveImage(index)} />
                                    </ThemeProvider>
                                </View>
                            }
                            ListEmptyComponent={this._listEmptyComponent}
                        />

                        {/* <RNPickerSelect
                        style={{ ...pickerSelectStyles }}
                        onValueChange={(value) => console.log(value)}
                        items={this.state.categoryItems}
                    /> */}

                        {this.state.warning_shop &&
                            <Text style={styles.warning_item}>請選擇店鋪Please Select Shop</Text>}

                        <Button
                            style={{ marginTop: 20, marginBottom: 20 }}
                            large
                            title="Submit"
                            onPress={this.onPressButton.bind(this)}
                        />
                    </View>
                </View >

            );
        } else {
            return (
                <View>

                </View>
            );
        }
    }
}

async function getBrands(uuid, token, email) {
    try {
        return fetch(path + 'inventory/brands', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            _body: JSON.stringify({
                version: '1',
                platform: 'ios',
                uuid: uuid,
                token: token,
                username: email,
                all_langs: 'yes',
            }),
            get body() {
                return this._body;
            },
            set body(value) {
                this._body = value;
            },
        })
            .then((response) => response.json())
        // return responseJson.data.payment_methods;
    }
    catch (error) {
        console.error(error);
    }
}

const styles = StyleSheet.create({
    text: {
        marginTop: 10,
        marginBottom: 2,
        fontSize: 20,
    },
    warning_item: {
        color: 'red',
        fontSize: 20,
        margin: 5,
    },
    title: {
        marginTop: 10,
        fontSize: 30,
    },
    inputfield: {
        borderRadius: 8,
        borderWidth: 2,
        color: 'black',
        borderColor: '#4189D6',
        paddingLeft: 10,
        fontSize: 20,
        height: 40
    },
    image: {
        maxWidth: '100%',
        height: 200,
        marginTop: 25,
        justifyContent: 'center'
    },
    bannner: {
        alignSelf: 'center',
        resizeMode: "stretch",

        width: '40%',
        maxWidth: '100%',
        height: 200,
    },
    border: {
        marginTop: 10,
        borderColor: '#000',
        borderRadius: 5,
        borderWidth: 2,
        padding: 10,
    },
});


const theme = {
    colors: {
        primary: 'red',
    }
}

async function createProduct(product_id, email, uuid, shop_ids, token, sku, price, isEnabled,
    name_en, name_zh_TW, name_zh_CN,
    description_en, description_zh_TW, description_zh_CN,
    packing_en, packing_zh_TW, packing_zh_CN,
    short_description_en, short_description_zh_TW, short_description_zh_CN,
    category_ids, bar_code, keywords, keywords_en, keywords_zh_TW, keywords_zh_CN, images, remove_image_ids, ordering, brand_id) {

    const data = new FormData();

    data.append('product_id', product_id);
    data.append('username', email)
    data.append('uuid', uuid);
    data.append('shop_ids', '[' + shop_ids + ']');
    data.append('token', token);
    data.append('price', price);
    data.append('enable', isEnabled);
    data.append('name_en', name_en);
    data.append('name_zh_TW', name_zh_TW);
    data.append('name_zh_CN', name_zh_CN);
    data.append('description_en', description_en);
    data.append('description_zh_TW', description_zh_TW);
    data.append('description_zh_CN', description_zh_CN);
    data.append('packing_en', packing_en);
    data.append('packing_zh_TW', packing_zh_TW);
    data.append('packing_zh_CN', packing_zh_CN);
    data.append('short_description_en', short_description_en);
    data.append('short_description_zh_TW', short_description_zh_TW);
    data.append('short_description_zh_CN', short_description_zh_CN);
    data.append('sku', sku);
    data.append('bar_code', bar_code);
    //data.append('keywords', '[' + keywords + ']');
    data.append('keywords_en', keywords_en);
    data.append('keywords_zh_TW', keywords_zh_TW);
    data.append('keywords_zh_CN', keywords_zh_CN);
    data.append('remove_image_ids', '[' + remove_image_ids + ']');
    data.append('category_ids', '[' + category_ids + ']');
    data.append('all_langs', 'yes')
    data.append('ordering', ordering)
    data.append('brand_id', brand_id);

    images.forEach((image) => {
        data.append('images[]', image);
    });

    try {
        return fetch(path + 'inventory/updateProduct', {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            _body: data,
            get body() {
                return this._body;
            },
            set body(value) {
                this._body = value;
            },
        })
            .then((response) => response.json())
    }
    catch (error) {
        console.error(error);
    }
}

const pickerSelectStyles = StyleSheet.create({
    inputIOS: {
        height: 40,
        paddingLeft: 10,
        borderWidth: 2,
        borderColor: '#4189D6',
        borderRadius: 4,
        color: 'black',
        paddingRight: 30, // to ensure the text is never behind the icon
    },
    inputAndroid: {
        height: 40,
        paddingLeft: 10,
        borderWidth: 2,
        borderColor: '#4189D6',
        borderRadius: 8,
        color: 'black',
        paddingRight: 30, // to ensure the text is never behind the icon
    }
});



export default EditProduct;