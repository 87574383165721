import React, { Component } from 'react';
import { Image, StyleSheet, Alert, View, TextInput, Text, Platform, ActivityIndicator } from 'react-native';
import { ThemeProvider, Button } from 'react-native-elements';
import { AsyncStorage } from 'react-native';
import { Redirect } from 'react-router';
import path from './serverPath.js'
import imagePath from './storagePath.js'
import SwitchSelector from 'react-native-switch-selector';

import ImagePicker from "react-native-everywhere-image-picker";

class EditBanner extends Component {

    constructor(props) {
        super(props);
        this.state = {
            image: '',
            previewImage: '',
            url: '',
            ordering: '',

            new_page: 'no',
            switch_position: 0,
            switch_options: [],

            enable: 'no',
            enable_position: 0,
            enable_options: [],

            banner: null,
        };
    }

    async onPressButton() {
        const uuid = await AsyncStorage.getItem('uuid');
        const token = await AsyncStorage.getItem('token');
        const user_info = await AsyncStorage.getItem('user_info');
        const userInfo = JSON.parse(user_info);

        updateBanner(userInfo.email, uuid, token, this.state.banner.banner_id, this.state.enable, this.state.new_page, this.state.url).then(data => {
            console.log(data)
            if (data.result == 0) {
                this.props.history.push('/bannerList')
            }
        });
    }


    async componentDidMount() {

        this.setState({ banner: this.props.location.state });

        const enable_options = [
            { label: "停用Disable", value: 'no' },
            { label: "啟用Enable", value: 'yes' },
        ];

        var enable = ''
        var enable_position = 0

        if (this.props.location.state.enable === 'yes') {
            enable = 'yes'
            enable_position = 1
        } else if (this.props.location.state.enable === 'no') {
            enable = 'no'
            enable_position = 0
        }

        const switch_options = [
            { label: "否No", value: 'no' },
            { label: "是Yes", value: 'yes' },
        ];

        var new_page = ''
        var switch_position = 0

        if (this.props.location.state.new_page === 'yes') {
            new_page = 'yes'
            switch_position = 1
        } else if (this.props.location.state.new_page === 'no') {
            new_page = 'no'
            switch_position = 0
        }

        var url = this.props.location.state.url

        if (url == null) {
            url = '';
        }

        this.setState({
            switch_options,
            new_page,
            switch_position,

            enable_options,
            enable,
            enable_position,

            previewImage: imagePath + this.props.location.state.image,
            ordering: this.props.location.state.ordering,
            url
        })
    }

    async handleRemoveImage() {
        const uuid = await AsyncStorage.getItem('uuid');
        const token = await AsyncStorage.getItem('token');
        const user_info = await AsyncStorage.getItem('user_info');
        const userInfo = JSON.parse(user_info);

        deleteBanner(userInfo.email, uuid, token, this.state.banner.banner_id).then(data => {
            console.log(data)
            if (data.result == 0) {
                this.props.history.push('/bannerList')
            }
        });
    }

    onPressBack = () => {
        this.props.history.push('/bannerList');
    }

    switchSelected(value) {
        if (value == 'no') {
            this.setState({ new_page: 'no', switch_position: 0 })
        } else {
            this.setState({ new_page: 'yes', switch_position: 1 })
        }
    }
    switchEnableSelected(value) {
        if (value == 'no') {
            this.setState({ enable: 'no', enable_position: 0 })
        } else {
            this.setState({ enable: 'yes', enable_position: 1 })
        }
    }

    render() {
        if (this.state.banner != null) {
            return (
                <View style={styles.container}>
                    <Image style={styles.banner} source={require('./image/hippos_banner.png')} />
                    <Text style={styles.title}>編輯橫幅 Edit Banner</Text>
                    <View style={styles.login_border}>
                        <Text style={styles.listedItem}>新一頁 New Page</Text>
                        <View style={{ width: '30%', borderRadius: 50, borderColor: '#4189D6', borderWidth: 2 }}>
                            <SwitchSelector
                                options={this.state.switch_options}
                                initial={this.state.switch_position}
                                buttonColor={'#4189D6'}
                                onPress={(value) => this.switchSelected(value)}
                            />
                        </View>

                        <View>
                            <Text style={styles.text}>網頁連結 URL</Text>
                            <TextInput
                                style={styles.inputfield}
                                placeholder="網頁連結 URL"
                                onChangeText={(text) => { this.setState({ url: text }) }}
                                value={`${this.state.url}`}
                            />
                        </View>

                        <Text style={styles.text}>啟用Enable/停用Disable</Text>
                        <View style={{ width: '30%', borderRadius: 50, borderColor: '#4189D6', borderWidth: 2 }}>
                            <SwitchSelector
                                options={this.state.enable_options}
                                initial={this.state.enable_position}
                                buttonColor={'#4189D6'}
                                onPress={(value) => this.switchEnableSelected(value)}
                            />
                        </View>

                        <View>
                            <Text style={styles.text}>次序 Ordering</Text>
                            <TextInput
                                style={styles.inputfield}
                                placeholder="e.g. 1/2/3/4, 數字愈細愈前"
                                onChangeText={(text) => { this.setState({ ordering: text }) }}
                                value={`${this.state.ordering}`}
                            />
                        </View>

                        {this.state.previewImage != '' &&
                            <Image source={{ uri: this.state.previewImage }} style={styles.image} />}
                        <Button
                            large
                            style={{ paddingTop: 10, paddingBottom: 10 }}
                            title="更新 Update"
                            onPress={this.onPressButton.bind(this)}
                        />

                        <ThemeProvider theme={theme} >
                            <Button title='刪除 Remove'
                                onPress={() => this.handleRemoveImage(this)} />
                        </ThemeProvider>

                        <Button
                            style={{ marginTop: 10 }}
                            large
                            title="返回 Back"
                            onPress={this.onPressBack.bind(this)}
                        />

                    </View>

                </View>
            );
        } else {
            return (
                <View style={[styles.container, styles.horizontal]}>
                    <ActivityIndicator size="large" color="#0000ff" />
                </View>
            )
        }
    }
}

async function updateBanner(email, uuid, token, banner_id, enable, new_page, url) {

    const data = new FormData();

    data.append('username', email);
    data.append('uuid', uuid);
    data.append('token', token);
    data.append('banner_id', banner_id);
    data.append('enable', enable);
    data.append('new_page', new_page);
    data.append('ordering', '1');
    data.append('url', url);

    try {
        return fetch(path + 'updateBanner', {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            _body: data,
            get body() {
                return this._body;
            },
            set body(value) {
                this._body = value;
            },
        })
            .then((response) => response.json())
    }
    catch (error) {
        console.error(error);
    }
}

async function deleteBanner(email, uuid, token, banner_id) {

    const data = new FormData();

    data.append('username', email);
    data.append('uuid', uuid);
    data.append('token', token);
    data.append('banner_id', banner_id);

    try {
        return fetch(path + 'deleteBanner', {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            _body: data,
            get body() {
                return this._body;
            },
            set body(value) {
                this._body = value;
            },
        })
            .then((response) => response.json())
    }
    catch (error) {
        console.error(error);
    }
}

const theme = {
    colors: {
        primary: 'red',
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    listedItem: {
        fontSize: 20,
        margin: 5,
    },
    title: {
        marginTop: 10,
        fontSize: 30,
    },
    input: {
        fontSize: 20,
        margin: 5,
        marginBottom: 10,
        borderWidth: 2,  // size/width of the border
        borderColor: 'lightgrey',  // color of the border
        height: 30
    },
    login_border: {
        marginTop: 10,
        width: '70%',
        borderColor: '#000',
        borderRadius: 5,
        borderWidth: 2,
        padding: 10,
    },
    image: {
        padding: 10,
        alignSelf: 'center',
        resizeMode: 'contain',
        width: '100%',
        height: 200,
    },
    banner: {
        padding: 10, alignSelf: 'center',
        resizeMode: "contain",
        flex: 1,
        width: '50%',
        maxWidth: '100%',
        height: 200,
    },
    text: {
        marginTop: 10,
        marginBottom: 2,
        fontSize: 20,
    },
    inputfield: {
        borderRadius: 8,
        borderWidth: 2,
        color: 'black',
        borderColor: '#4189D6',
        paddingLeft: 10,
        fontSize: 20,
        height: 40
    },
});

export default EditBanner;