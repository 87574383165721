import React, { Component } from 'react';
import { Image, StyleSheet, View, Text, FlatList, TouchableOpacity, ActivityIndicator } from 'react-native';
import { ThemeProvider, Button } from 'react-native-elements';
import { AsyncStorage } from 'react-native';
import RNPickerSelect from 'react-native-picker-select';
import path from './serverPath.js'
import { FlatGrid } from 'react-native-super-grid';


class ProductList extends Component {


  handlePress = products => {
    this.props.history.push('/editProduct', products);
  };

  handlePagePress = page => {
    getShopProducts(this.state.uuid, this.state.token, this.state.username, this.state.shopId, page, this.state.brandId).then(data => {
      var products = data.data.products

      for (let product of products) {
        if (product.cover == "" || product.cover == null) {
          product.cover = 'http://hippos.beuniquewedding.com/img/icon.png'
        }
      }

      this.setState({
        products,
        now_page: page,
        pagination: data.data.pagination,
      });
    });
  };


  onPressButton() {
    this.props.history.push('/createProduct');
  }

  onCreateCategory() {
    this.props.history.push('/createCategory');
  }

  onEditCategory() {
    this.props.history.push('/categoryList');
  }

  constructor(props) {
    super(props);
    this.state = {
      shops: null,
      shopItem: [],
      shopId: '',
      products: null,
      pagination: null,
      pages: null,
      now_page: 1,
      uuid: '',
      token: '',
      username: '',
      brands: null,
      brandItem: [],
      brandId: '',
    };
  }

  async componentDidMount() {

    const uuid = await AsyncStorage.getItem('uuid');
    const token = await AsyncStorage.getItem('token');
    const user_info = await AsyncStorage.getItem('user_info');
    const userInfo = JSON.parse(user_info);

    const default_lang = await AsyncStorage.getItem('default_lang')

    this.setState({
      uuid,
      token,
      username: userInfo.email,
    })

    getshops(uuid, token, userInfo.email).then(data => {
      const shops = data.data.shops
      var shopItem = [];
      for (let shop of shops) {
        shopItem.push({ label: shop.name, value: shop.id });
      }

      this.setState({ shops, shopItem, shopId: shops[0].id })

      getBrands(uuid, token, userInfo.email).then(data => {
        console.log(data)
        var brands = data.data.brands

        var brandItem = [];
        for (let brand of brands) {
          if (default_lang == "en") {
            brandItem.push({ label: brand.name_en, value: brand.id });
          } else if (default_lang == "zh_TW") {
            brandItem.push({ label: brand.name_zh_TW, value: brand.id });
          } else {
            brandItem.push({ label: brand.name_zh_CN, value: brand.id });
          }
        }

        if (brands != null) {
          this.setState({
            brands,
            brandItem,
            brandId: brands[0].id
          })
          getShopProducts(uuid, token, userInfo.email, shops[0].id, '1', brands[0].id).then(data => {
            var pages = [];
            for (var i = 1; i <= data.data.pagination.total_page; i++) {
              pages.push(i);
            }

            var products = data.data.products
            for (let product of products) {
              if (product.cover == "" || product.cover == null) {
                product.cover = 'http://hippos.beuniquewedding.com/img/icon.png'
              }
            }

            this.setState({
              products,
              pagination: data.data.pagination,
              pages,
            });
          });
        } else {
          this.setState({
            brands,
            brandItem,
          })

          getShopProducts(uuid, token, userInfo.email, shops[0].id, '1', "").then(data => {
            var pages = [];
            for (var i = 1; i <= data.data.pagination.total_page; i++) {
              pages.push(i);
            }

            var products = data.data.products
            for (let product of products) {
              if (product.cover == "" || product.cover == null) {
                product.cover = 'http://hippos.beuniquewedding.com/img/icon.png'
              }
            }

            this.setState({
              products,
              pagination: data.data.pagination,
              pages,
            });
          });
        }
      })
    })


    // getProducts(uuid, token, userInfo.email, '1').then(data => {
    //   console.log(data);

    //   var pages = [];
    //   for (var i = 1; i <= data.data.pagination.total_page; i++) {
    //     pages.push(i);
    //   }

    //   var products = data.data.products
    //   for (let product of products) {
    //     if (product.cover == "" || product.cover == null) {
    //       product.cover = 'http://hippos.beuniquewedding.com/img/icon.png'
    //     }
    //   }

    //   console.log(products);

    //   this.setState({
    //     products,
    //     pagination: data.data.pagination,
    //     pages,
    //     uuid,
    //     token,
    //     username: userInfo.email,
    //   });
    // });
  }

  onPressBack = () => {
    this.props.history.push('/selectAction');
  }

  onSelect(value) {
    getShopProducts(this.state.uuid, this.state.token, this.state.username, value, '1', this.state.brandId).then(data => {

      var pages = [];
      for (var i = 1; i <= data.data.pagination.total_page; i++) {
        pages.push(i);
      }

      var products = data.data.products
      for (let product of products) {
        if (product.cover == "" || product.cover == null) {
          product.cover = 'http://hippos.beuniquewedding.com/img/icon.png'
        }
      }

      this.setState({
        products,
        pagination: data.data.pagination,
        pages,
      });
    });

    this.setState({
      shopId: value
    })
  }

  onSelectBrand(value) {
    console.log(value)
    getShopProducts(this.state.uuid, this.state.token, this.state.username, this.state.shopId, '1', value).then(data => {
      console.log(data)
      var pages = [];
      for (var i = 1; i <= data.data.pagination.total_page; i++) {
        pages.push(i);
      }

      var products = data.data.products
      for (let product of products) {
        if (product.cover == "" || product.cover == null) {
          product.cover = 'http://hippos.beuniquewedding.com/img/icon.png'
        }
      }

      this.setState({
        products,
        pagination: data.data.pagination,
        pages,
      });
    });

    this.setState({
      brandId: value
    })
  }

  onLogout() {
    AsyncStorage.setItem('uuid', null);
    AsyncStorage.setItem('token', null);
    AsyncStorage.setItem('user_info', null)
    this.props.history.push('/');
  }

  render() {
    if (this.state.shopId == '' || this.state.products == null) {
      return (
        <View style={[styles.container, styles.horizontal]}>
          <ActivityIndicator size="large" color="#0000ff" />
        </View>
      )
    } else {
      return (
        <View >
          <View style={{ flexDirection: 'row', width: "100%" }}>
            <Image style={styles.image} source={require('./image/hippos_banner.png')} />
            <View style={{ marginLeft: 20, flexDirection: 'column-reverse' }}>
              <View style={{
                marginTop: 10,
              }}>
                <Button
                  title="新增貨品 Create Product"
                  onPress={this.onPressButton.bind(this)}
                />
              </View>
              <Button
                style={{ marginTop: 10 }}
                title="編輯分類 Edit Category"
                onPress={this.onEditCategory.bind(this)}
              />
              <Button
                style={{ marginTop: 10 }}
                large
                title="返回 Back"
                onPress={this.onPressBack.bind(this)}
              />
            </View>
            <View style={{ marginLeft: 20, flexDirection: 'column-reverse' }}>
              <View style={{
                // alignItems: "center",
                width: '100%',
                marginTop: 10,
                marginBottom: 10,
                flexDirection: 'row'
              }}>
                <Text style={{ fontSize: 18, marginRight: 5, marginTop: 10 }}>店鋪 Shop : </Text>
                <RNPickerSelect
                  value={this.state.shopId}
                  style={{ ...pickerSelectStyles }}
                  onValueChange={this.onSelect.bind(this)}
                  // onValueChange={(value) => console.log(value)}
                  items={this.state.shopItem}
                />
              </View>

              <View style={{
                // alignItems: "center",
                width: '100%',
                marginTop: 10,
                marginBottom: 10,
                flexDirection: 'row'
              }}>
                <Text style={{ fontSize: 18, marginRight: 5, marginTop: 10 }}>品牌 Brand : </Text>
                <RNPickerSelect
                  value={this.state.brandId}
                  style={{ ...pickerSelectStyles }}
                  onValueChange={this.onSelectBrand.bind(this)}
                  // onValueChange={(value) => console.log(value)}
                  items={this.state.brandItem}
                />
              </View>

              {/* <Button
                title="登出 Logout"
                onPress={this.onLogout.bind(this)}
              /> */}
            </View>

          </View>

          <View >
            <FlatGrid
              itemDimension={300}
              items={this.state.products}
              renderItem={({ item }) => {
                if (item.enable == 'yes') {
                  return (
                    <TouchableOpacity onPress={() => this.handlePress(item)}>
                      <View style={styles.list_border}>
                        <View >
                          <Image source={item.cover} style={styles.cover} />
                        </View>
                        <View>
                          <Text numberOfLines={1} style={styles.listedItemName}>{item.name_en}</Text>
                          <Text style={styles.listedItem}>{`貨存Inventory : ` + item.inventory}</Text>
                          <Text style={styles.listedItem}>{`價錢Price : ` + item.price}</Text>
                          <Text style={styles.listedItemComplete}>{`狀態Status :  啟用中 `}</Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  )
                } else {
                  return (
                    <TouchableOpacity onPress={() => this.handlePress(item)}>
                      <View style={styles.list_border}>
                        <View >
                          <Image source={item.cover} style={styles.cover} />
                        </View>
                        <View>
                          <Text numberOfLines={1} style={styles.listedItemName}>{item.name_en}</Text>
                          <Text style={styles.listedItem}>{`貨存Inventory : ` + item.inventory}</Text>
                          <Text style={styles.listedItem}>{`價錢Price : ` + item.price}</Text>
                          <Text style={styles.listedItemCancel}>{`狀態Status :  已停用 `}</Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  )
                }
              }}
              keyExtractor={item => item.id}
            />

            {/* <FlatList
              contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
              data={this.state.products}
              showsVerticalScrollIndicator={false}
              renderItem={({ item }) =>
                <TouchableOpacity onPress={() => this.handlePress(item)}>
                  <View style={styles.list_border}>
                    <View >
                      <Image source={item.cover} style={styles.cover} />
                    </View>
                    <View>
                      <Text style={styles.listedItem}>{`貨品名稱Name : ` + item.name_en}</Text>
                      <Text style={styles.listedItem}>{`貨存Inventory : ` + item.inventory}</Text>
                      <Text style={styles.listedItem}>{`價錢Price : ` + item.price}</Text>
                    </View>
                  </View>
                </TouchableOpacity>
              }
              keyExtractor={item => item.id}
            /> */}

            {/* <View style={{
            // alignItems: "center",
            width: '100%',
            marginTop: 10,
            marginBottom: 10
          }}>
            <Button
              title="新增貨品Create Product"
              onPress={this.onPressButton.bind(this)}
            />
          </View> */}

            <View style={{ justifyContent: 'center', flexDirection: 'row' }}>
              <Text style={{ fontSize: 18, marginRight: 5 }}>分頁Page:</Text>
              <FlatList
                data={this.state.pages}
                ItemSeparatorComponent={this.space}
                horizontal={true}
                extraData={this.state}
                renderItem={({ item }) => {
                  if (item == this.state.now_page) {
                    return (
                      <Text style={styles.selectedPageNum}>{item}</Text>
                    )
                  } else {
                    return (
                      <TouchableOpacity onPress={() => this.handlePagePress(item)}>
                        <Text style={styles.listedPageNum}>{item}</Text>
                      </TouchableOpacity>
                    )
                  }
                }
                }
              />
            </View>
          </View>
        </View >
      );
    }
  }
}

async function getshops(uuid, token, email) {
  try {
    return fetch(path + 'inventory/shops', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      _body: JSON.stringify({
        version: '1',
        platform: 'ios',
        uuid: uuid,
        token: token,
        username: email,
      }),
      get body() {
        return this._body;
      },
      set body(value) {
        this._body = value;
      },
    })
      .then((response) => response.json())
    // return responseJson.data.payment_methods;
  }
  catch (error) {
    console.error(error);
  }
}

async function getShopProducts(uuid, token, email, shop_id, page, brandId) {
  try {
    return fetch(path + 'inventory/shopProducts', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      _body: JSON.stringify({
        version: '1',
        platform: 'ios',
        uuid: uuid,
        token: token,
        username: email,
        shop_id: shop_id,
        page: page,
        brand_id: brandId,
        per_page: '24',
        all_langs: 'yes',
      }),
      get body() {
        return this._body;
      },
      set body(value) {
        this._body = value;
      },
    })
      .then((response) => response.json())
    // return responseJson.data.payment_methods;
  }
  catch (error) {
    console.error(error);
  }
}

async function getBrands(uuid, token, email) {
  try {
    return fetch(path + 'inventory/brands', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      _body: JSON.stringify({
        version: '1',
        platform: 'ios',
        uuid: uuid,
        token: token,
        username: email,
        all_langs: 'yes',
      }),
      get body() {
        return this._body;
      },
      set body(value) {
        this._body = value;
      },
    })
      .then((response) => response.json())
    // return responseJson.data.payment_methods;
  }
  catch (error) {
    console.error(error);
  }
}

async function getProducts(uuid, token, email, page) {
  try {
    return fetch(path + 'inventory/products', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      _body: JSON.stringify({
        version: '1',
        platform: 'ios',
        uuid: uuid,
        token: token,
        username: email,
        page: page,
        all_langs: 'yes',
      }),
      get body() {
        return this._body;
      },
      set body(value) {
        this._body = value;
      },
    })
      .then((response) => response.json())
    // return responseJson.data.payment_methods;
  }
  catch (error) {
    console.error(error);
  }
}

const styles = StyleSheet.create({
  listedItem: {
    fontSize: 14,
    // background: '#285b97',
    borderRadius: 3,
    padding: 5,
    marginLeft: 2,
  },
  listedItemName: {
    fontSize: 16,
    // background: '#285b97',
    flexWrap: 'wrap',
    width: 200,
    borderRadius: 3,
    padding: 5,
    marginLeft: 2,
  },
  listedPageNum: {
    fontSize: 18,
    marginRight: 5,
    textDecorationLine: 'underline',
    color: 'blue'
  },
  selectedPageNum: {
    fontSize: 20,
    marginRight: 5,
    color: 'Black'
  },
  listedItemCancel: {
    fontSize: 14,
    color: 'red',
    borderRadius: 3,
    padding: 5,
    marginLeft: 2,
  },
  listedItemComplete: {
    fontSize: 14,
    color: '#62C377',
    // background: '#285b97',
    borderRadius: 3,
    padding: 5,
    marginLeft: 2,
  },
  image: {
    resizeMode: "contain",
    width: '30%',
    height: 120,
    marginTop: 10,
  },
  border: {
    marginTop: 10,
    width: '100%',
    borderColor: '#000',
    borderRadius: 5,
    borderWidth: 2,
    padding: 10,
  },
  cover: {
    resizeMode: "stretch",
    flex: 1,
    width: 100,
    height: 100,
  },
  list_border: {
    marginTop: 10,
    width: '100%',
    borderColor: '#000',
    borderRadius: 5,
    borderWidth: 2,
    padding: 10,
    flexGrow: 1,
    flexDirection: 'row'
  }
});

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    height: 40,
    paddingLeft: 10,
    borderWidth: 2,
    borderColor: '#4189D6',
    borderRadius: 4,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
  },
  inputAndroid: {
    height: 40,
    paddingLeft: 10,
    borderWidth: 2,
    borderColor: '#4189D6',
    borderRadius: 8,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
  }
});



export default ProductList;