import React, { Component } from 'react';
import { Image, StyleSheet, Alert, View, TextInput, Text, Platform } from 'react-native';
import { ThemeProvider, Button } from 'react-native-elements';
import { AsyncStorage } from 'react-native';
import { Redirect } from 'react-router';
import path from './serverPath.js'
import SwitchSelector from 'react-native-switch-selector';

import ImagePicker from "react-native-everywhere-image-picker";

class EditBrand extends Component {

    constructor(props) {
        super(props);
        this.state = {
            uuid: '',
            token: '',
            email: '',

            image: '',
            previewImage: '',

            brand : null,

            name_en: '',
            name_zh_TW: '',
            name_zh_CN: '',
        };
    }

    async onPressButton() {
        updateBrand(this.state.email, this.state.uuid, this.state.token, this.state.brand.id, this.state.name_en, this.state.name_zh_TW, this.state.name_zh_CN, this.state.image).then(data => {
            console.log(data)
            if (data.result == 0) {
                this.props.history.push('/brandList')
            }
        });
    }


    async componentDidMount() {
        const uuid = await AsyncStorage.getItem('uuid');
        const token = await AsyncStorage.getItem('token');
        const user_info = await AsyncStorage.getItem('user_info');
        const userInfo = JSON.parse(user_info);

        this.setState({
            uuid,
            token,
            email: userInfo.email,
        })

        const brand = this.props.location.state

        this.setState({
            brand,
            previewImage: brand.logo,
            name_en: brand.name_en,
            name_zh_TW: brand.name_zh_TW,
            name_zh_CN: brand.name_zh_CN,
        })
    }

    onPressBack = () => {
        this.props.history.push('/selectCompanyAction');
    }

    _handleImage = ({ uri }) => {

        var startIndex = uri.indexOf("base64,") + 7;
        var b64 = uri.substr(startIndex);
        var byteCharacters = atob(b64);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var image = new Blob([byteArray], { type: 'image/png' });

        this.setState(() => ({ image, previewImage: uri }));

    };

    handleRemoveImage() {
        this.setState({
            image: '', previewImage: ''
        })
    }

    doDeleteBrand(){
        deleteBrand(this.state.email, this.state.uuid, this.state.token, this.state.brand.id).then(data => {
            console.log(data)
            if (data.result == 0) {
                this.props.history.push('/brandList')
            }
        });
    }

    render() {
        return (
            <View style={styles.container}>
                <Image style={styles.banner} source={require('./image/hippos_banner.png')} />
                <Text style={styles.title}>編輯品牌 Edit Brand</Text>
                <View style={styles.login_border}>

                    <View>
                        <Text style={styles.text}>品牌名稱 Brand Name（EN）</Text>
                        <TextInput
                            style={styles.inputfield}
                            placeholder="品牌名稱 Brand Name（EN）"
                            onChangeText={(text) => { this.setState({ name_en: text }) }}
                            value={`${this.state.name_en}`}
                        />
                    </View>

                    <View>
                        <Text style={styles.text}>品牌名稱 Brand Name（繁體）</Text>
                        <TextInput
                            style={styles.inputfield}
                            placeholder="品牌名稱 Brand Name（繁體）"
                            onChangeText={(text) => { this.setState({ name_zh_TW: text }) }}
                            value={`${this.state.name_zh_TW}`}
                        />
                    </View>

                    <View>
                        <Text style={styles.text}>品牌名稱 Brand Name（簡體）</Text>
                        <TextInput
                            style={styles.inputfield}
                            placeholder="品牌名稱 Brand Name（簡體）"
                            onChangeText={(text) => { this.setState({ name_zh_CN: text }) }}
                            value={`${this.state.name_zh_CN}`}
                        />
                    </View>

                    <Text style={styles.text}>品牌Logo</Text>
                    <ImagePicker onComplete={this._handleImage}>
                        <Button
                            style={{ marginBottom: 20 }}
                            large
                            title="選擇圖片Select Image"
                        />
                    </ImagePicker>

                    {this.state.previewImage != '' &&
                        <Image source={{ uri: this.state.previewImage }} style={styles.image} />}
                    {/* {this.state.previewImage != '' &&
                        <ThemeProvider theme={theme} >
                            <Button title='刪除 Remove'
                                onPress={() => this.handleRemoveImage(this)} />
                        </ThemeProvider>} */}

                    <Button
                        large
                        style={{ marginTop: 10 }}
                        title="更新品牌 Update Brand"
                        onPress={this.onPressButton.bind(this)}
                    />

                    <ThemeProvider theme={theme} >
                        <Button title='刪除品牌 Delete Brand'
                            style={{ marginTop: 10 }}
                            onPress={() => this.doDeleteBrand(this)} />
                    </ThemeProvider>

                    <Button
                        style={{ marginTop: 10 }}
                        large
                        title="返回 Back"
                        onPress={this.onPressBack.bind(this)}
                    />

                </View>

            </View>
        );
    }
}

async function updateBrand(email, uuid, token, brand_id, name_en, name_zh_TW, name_zh_CN, logo) {

    const data = new FormData();

    data.append('username', email);
    data.append('uuid', uuid);
    data.append('token', token);
    data.append('brand_id', brand_id)
    data.append('all_langs', 'yes');
    data.append('name_en', name_en);
    data.append('name_zh_TW', name_zh_TW)
    data.append('name_zh_CN', name_zh_CN)

    if (logo != null) {
        data.append('logo', logo);
    }

    try {
        return fetch(path + 'inventory/updateBrand', {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            _body: data,
            get body() {
                return this._body;
            },
            set body(value) {
                this._body = value;
            },
        })
            .then((response) => response.json())
    }
    catch (error) {
        console.error(error);
    }
}

async function deleteBrand(email, uuid, token, brand_id) {

    const data = new FormData();

    data.append('username', email);
    data.append('uuid', uuid);
    data.append('token', token);
    data.append('brand_id', brand_id);

    try {
        return fetch(path + 'inventory/deleteBrand', {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            _body: data,
            get body() {
                return this._body;
            },
            set body(value) {
                this._body = value;
            },
        })
            .then((response) => response.json())
    }
    catch (error) {
        console.error(error);
    }
}



const theme = {
    colors: {
        primary: 'red',
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    listedItem: {
        fontSize: 20,
        margin: 5,
    },
    title: {
        marginTop: 10,
        fontSize: 30,
    },
    input: {
        fontSize: 20,
        margin: 5,
        marginBottom: 10,
        borderWidth: 2,  // size/width of the border
        borderColor: 'lightgrey',  // color of the border
        height: 30
    },
    login_border: {
        marginTop: 10,
        width: '70%',
        borderColor: '#000',
        borderRadius: 5,
        borderWidth: 2,
        padding: 10,
    },
    image: {
        padding: 10,
        alignSelf: 'center',
        resizeMode: 'contain',
        width: '100%',
        height: 200,
    },
    banner: {
        padding: 10, alignSelf: 'center',
        resizeMode: "contain",
        flex: 1,
        width: '50%',
        maxWidth: '100%',
        height: 200,
    },
    text: {
        marginTop: 10,
        marginBottom: 2,
        fontSize: 20,
    },
    inputfield: {
        borderRadius: 8,
        borderWidth: 2,
        color: 'black',
        borderColor: '#4189D6',
        paddingLeft: 10,
        fontSize: 20,
        height: 40
    },
});

export default EditBrand;