import React, { Component } from 'react';
import { Image, StyleSheet, Alert, View, TextInput, Text, Platform, ActivityIndicator } from 'react-native';
import { Button } from 'react-native-elements';
import { AsyncStorage } from 'react-native';
import { Redirect } from 'react-router';
import RNPickerSelect from 'react-native-picker-select';
import path from './serverPath.js'

class SelectCompanyAction extends Component {

    constructor(props) {
        super(props);
        this.state = {
            uuid: '',
            token: '',
            username: '',
        };
    }

    async componentDidMount() {
        const uuid = await AsyncStorage.getItem('uuid');
        const token = await AsyncStorage.getItem('token');
        const user_info = await AsyncStorage.getItem('user_info');
        const userInfo = JSON.parse(user_info);

        this.setState({ uuid, token, username: userInfo.email })
    }

    onCreateBrand() {
        this.props.history.push('/createBrand');
    }

    onEditBrand() {
        this.props.history.push('/brandList')
    }

    onEditCompany() {
        this.props.history.push('/editCompany');
    }

    onCreateBanner() {
        this.props.history.push('/createBanner');
    }

    onEditBanner() {
        this.props.history.push('/bannerList')
    }

    onPressBack() {
        this.props.history.push('/')
    }

    onCreateDeliveryCost(){
        this.props.history.push('/createDeliveryCost')
    }

    onEditDeliveryCost(){
        this.props.history.push('/deliveryCostList')
    }

    render() {
        if (this.state.uuid != '' && this.state.token != '') {
            return (
                <View style={styles.container}>
                    <Image style={styles.image} source={require('./image/hippos_banner.png')} />

                    <Text style={styles.title}>HIPPOS倉存系統</Text>
                    <View style={styles.login_border}>
                        <Button
                            style={{ marginTop: 10 }}
                            large
                            title="新增橫幅 Create Banner"
                            onPress={this.onCreateBanner.bind(this)}
                        />
                        <Button
                            style={{ marginTop: 10 }}
                            large
                            title="編輯橫幅 Edit Banner"
                            onPress={this.onEditBanner.bind(this)}
                        />
                        <Button
                            style={{ marginTop: 10 }}
                            large
                            title="新增品牌 Create Brand"
                            onPress={this.onCreateBrand.bind(this)}
                        />
                        <Button
                            style={{ marginTop: 10 }}
                            large
                            title="編輯品牌 Edit Brand"
                            onPress={this.onEditBrand.bind(this)}
                        />
                        <Button
                            style={{ marginTop: 10 }}
                            large
                            title="新增運費 Create Delivery Cost"
                            onPress={this.onCreateDeliveryCost.bind(this)}
                        />
                        <Button
                            style={{ marginTop: 10 }}
                            large
                            title="編輯運費 Edit Delivery Cost"
                            onPress={this.onEditDeliveryCost.bind(this)}
                        />
                        <Button
                            style={{ marginTop: 10 }}
                            large
                            title="編輯公司設定 Edit Company Info"
                            onPress={this.onEditCompany.bind(this)}
                        />
                        <Button
                            style={{ marginTop: 10 }}
                            title="返回 Back"
                            onPress={this.onPressBack.bind(this)}
                        />
                    </View>

                </View>
            );
        } else {
            return (
                <View style={[styles.container, styles.horizontal]}>
                    <ActivityIndicator size="large" color="#0000ff" />
                </View>
            )
        }
    }
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    listedItem: {
        fontSize: 20,
        margin: 5,
    },
    title: {
        marginTop: 10,
        fontSize: 30,
    },
    input: {
        fontSize: 20,
        margin: 5,
        marginBottom: 10,
        borderWidth: 2,  // size/width of the border
        borderColor: 'lightgrey',  // color of the border
        height: 30
    },
    login_border: {
        marginTop: 10,
        width: '40%',
        borderColor: '#000',
        borderRadius: 5,
        borderWidth: 2,
        padding: 10,
    },
    image: {
        padding: 10, alignSelf: 'center',
        resizeMode: "stretch",
        flex: 1,
        width: '50%',
        maxWidth: '100%',
        height: 200,
    },
    warning_item: {
        color: 'red',
        fontSize: 20,
        margin: 5,
    },
});

const pickerSelectStyles = StyleSheet.create({
    inputIOS: {
        height: 40,
        paddingLeft: 10,
        borderWidth: 2,
        borderColor: '#4189D6',
        borderRadius: 4,
        color: 'black',
        paddingRight: 30, // to ensure the text is never behind the icon
    },
    inputAndroid: {
        height: 40,
        paddingLeft: 10,
        borderWidth: 2,
        borderColor: '#4189D6',
        borderRadius: 8,
        color: 'black',
        paddingRight: 30, // to ensure the text is never behind the icon
    }
});

export default SelectCompanyAction;