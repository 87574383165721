import React, { Component } from 'react';
import { Image, StyleSheet, View, Text, FlatList, TouchableOpacity } from 'react-native';
import { ThemeProvider, Button } from 'react-native-elements';
import { AsyncStorage } from 'react-native';
import path from './serverPath.js'


class StockOutList extends Component {


  handlePress = stocks => {
    this.props.history.push({
      pathname: '/stockOut',
      state: { stock: stocks, product: this.state.product }
    })
  };

  handlePagePress = page => {
    stockHistories(uuid, token, userInfo.email, '1', shop_id, product.id).then(data => {
      var pages = [];
      for (var i = 1; i <= data.data.pagination.total_page; i++) {
        pages.push(i);
      }

      var stocks = data.data.stocks
      for (let stock of stocks) {
        if (stock.product_cover_image == "" || stock.product_cover_image == null) {
          stock.cover = 'http://hippos.beuniquewedding.com/img/icon.png'
        }
      }

      this.setState({
        stocks,
        pagination: data.data.pagination,
        pages,
      });
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      product: null,
      stocks: null,
      pagination: null,
      pages: null,
      shop_id: '',
      uuid: '',
      token: '',
      username: '',
    };
  }

  async componentDidMount() {
    const uuid = await AsyncStorage.getItem('uuid');
    const token = await AsyncStorage.getItem('token');
    const user_info = await AsyncStorage.getItem('user_info');
    const userInfo = JSON.parse(user_info);

    const product = this.props.location.state
    const shop_id = product.shops[0].id

    stockHistories(uuid, token, userInfo.email, '1', shop_id, product.id).then(data => {
      console.log(data)
      var pages = [];
      for (var i = 1; i <= data.data.pagination.total_page; i++) {
        pages.push(i);
      }

      var stocks = data.data.stocks
      for (let stock of stocks) {
        if (stock.product_cover_image == "" || stock.product_cover_image == null) {
          stock.cover = 'http://hippos.beuniquewedding.com/img/icon.png'
        }
      }

      this.setState({
        product,
        stocks,
        pagination: data.data.pagination,
        pages,
        uuid,
        token,
        username: userInfo.email,
      });
    });
  }

  onPressBack = () => {
    this.props.history.push('/editProduct', this.state.product);
  }

  render() {
    return (

      <View >
        <View style={{ flexDirection: 'row', width: "100%" }}>
          <Image style={styles.image} source={require('./image/hippos_banner.png')} />
          <View style={{ marginLeft: 20, padding: 10, flexDirection: 'column-reverse' }}>
            <Button
              large
              title="返回 Back"
              onPress={this.onPressBack.bind(this)}
            />
          </View>
        </View>

        <View style={styles.border}>
          <FlatList
            contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
            data={this.state.stocks}
            showsVerticalScrollIndicator={false}
            renderItem={({ item }) => {
              if (item.type == 'in') {
                return (
                  <TouchableOpacity onPress={() => this.handlePress(item)}>
                    <View style={styles.list_border}>
                      <View >
                        <Image source={this.state.product.cover} style={styles.cover} />
                      </View>
                      <View>
                        <Text style={styles.listedItem}>{`貨品名稱Name : ` + item.product_name}</Text>
                        <Text style={styles.listedItem}>{`批次編號Batch Number :` + item.batch_number}</Text>
                        <Text style={styles.listedItem}>{`成本Cost : ` + item.cost}</Text>
                        <Text style={styles.listedItem}>{`數量 Qty : ` + item.qty}</Text>
                        <Text style={styles.listedItem}>{`剩餘數量 Remain Qty : ` + item.remaining_qty}</Text>
                      </View>
                    </View>
                  </TouchableOpacity>
                );
              } else {
                return (
                  <TouchableOpacity onPress={() => this.handlePress(item)}>
                    <View style={styles.list_border_w_color}>
                      <View >
                        <Image source={this.state.product.cover} style={styles.cover} />
                      </View>
                      <View>
                        <Text style={styles.listedItem}>{`貨品名稱Name : ` + item.product_name}</Text>
                        <Text style={styles.listedItem}>{`批次編號Batch Number :` + item.batch_number}</Text>
                        <Text style={styles.listedItem}>{`成本Cost : ` + item.cost}</Text>
                        <Text style={styles.listedItem}>{`數量Qty : -` + item.qty}</Text>
                      </View>
                    </View>
                  </TouchableOpacity>
                );
              }
            }}
            // renderItem={({ item }) =>

            //   <TouchableOpacity onPress={() => this.handlePress(item)}>
            //     <View style={styles.list_border}>
            //       <View >
            //         <Image source={item.product_cover_image} style={styles.cover} />
            //       </View>
            //       <View>
            //         <Text style={styles.listedItem}>{`貨品名稱Name : ` + item.product_name}</Text>
            //         <Text style={styles.listedItem}>{`批次編號Batch Number :` + item.batch_number}</Text>
            //         <Text style={styles.listedItem}>{`成本Cost : ` + item.cost}</Text>
            //         <Text style={styles.listedItem}>{`數量Qty : -` + item.qty}</Text>
            //       </View>
            //     </View>
            //   </TouchableOpacity>
            // }
            keyExtractor={item => item.id}
          />

          {/* <View style={{
            // alignItems: "center",
            width: '100%',
            marginTop: 10,
            marginBottom: 10
          }}>
            <Button
              title="新增貨品Create Product"
              onPress={this.onPressButton.bind(this)}
            />
          </View> */}

          <View style={{ justifyContent: 'center', flexDirection: 'row' }}>
            <Text style={{ fontSize: 18, marginRight: 5 }}>分頁Page:</Text>
            <FlatList
              data={this.state.pages}
              ItemSeparatorComponent={this.space}
              horizontal={true}
              renderItem={({ item }) =>
                <TouchableOpacity onPress={() => this.handlePagePress(item)}>
                  <Text style={styles.listedPageNum}>{item}</Text>
                </TouchableOpacity>
              }
            />
          </View>
        </View>
      </View >
    );
  }
}

async function stockHistories(uuid, token, email, page, shop_id, product_id) {
  try {
    return fetch(path + 'inventory/productStockHistories', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      _body: JSON.stringify({
        version: '1',
        platform: 'ios',
        uuid: uuid,
        token: token,
        username: email,
        page: page,
        shop_id: shop_id,
        product_id : product_id,
        all_langs: 'yes',
      }),
      get body() {
        return this._body;
      },
      set body(value) {
        this._body = value;
      },
    })
      .then((response) => response.json())
    // return responseJson.data.payment_methods;
  }
  catch (error) {
    console.error(error);
  }
}

const styles = StyleSheet.create({
  listedItem: {
    fontSize: 16,
    // background: '#285b97',
    borderRadius: 3,
    margin: 5,
  },
  listedPageNum: {
    fontSize: 18,
    marginRight: 5,
    textDecorationLine: 'underline',
    color: 'blue'
  },
  image: {
    resizeMode: "stretch",
    width: '30%',
    height: 100,
  }, 
  border: {
    marginTop: 10,
    width: '100%',
    borderColor: '#000',
    borderRadius: 5,
    borderWidth: 2,
    padding: 10,
  },
  cover: {
    resizeMode: "stretch",
    flex: 1,
    width: 120,
    height: 100,
  },
  list_border: {
    marginTop: 10,
    width: '100%',
    borderColor: '#000',
    borderRadius: 5,
    borderWidth: 2,
    padding: 10,
    flexGrow: 1,
    flexDirection: 'row'
  },
  list_border_w_color: {
    backgroundColor: 'gray',
    marginTop: 10,
    width: '100%',
    borderColor: '#000',
    borderRadius: 5,
    borderWidth: 2,
    padding: 10,
    flexGrow: 1,
    flexDirection: 'row'
  }
});

export default StockOutList;