import React, { Component } from 'react';
import { View, Text, FlatList, TouchableOpacity, TabBarIOS } from 'react-native';
import ShowPayment from './ShowPayment';
import { Redirect } from 'react-router';
import { AsyncStorage } from 'react-native';
import path from './serverPath.js'

class AppChecking extends Component {

    constructor(props) {
        super(props);
        this.state = {
            result_message: "",
            payment_methods: null,
            logined: "",
        };
    }

    async componentDidMount() {
        const uuid = await AsyncStorage.getItem('uuid');
        const token = await AsyncStorage.getItem('token');
        const user_info = await AsyncStorage.getItem('user_info');
        getAppChecking().then(data => {
            this.setState({
                result_message: data.result_message,
                payment_methods: data.data.payment_methods
            });
            try {
                if (uuid == null && token == null || user_info == null || uuid == 'null' && token == 'null' || user_info == 'null') {
                    checkLoginByToken().then(data => {
                        if (data.data.app_user_info != null) {
                            AsyncStorage.setItem('uuid', data.data.app_user_info.uuid);
                            AsyncStorage.setItem('token', data.data.app_user_info.token);
                            AsyncStorage.setItem('app_user_info', JSON.stringify(data.data.app_user_info))
                            this.setState({
                                logined: "unlogin"
                            })
                        } else if (data.data.user_info != null) {

                        }
                    });
                }
                else {
                    const userInfo = JSON.parse(user_info);
                    loginByToken(uuid, token, userInfo.email).then(data => {
                        console.log(data)
                        AsyncStorage.setItem('user_info', JSON.stringify(data.data.user_info))
                        AsyncStorage.setItem('categories', JSON.stringify(data.data.categories))
                        AsyncStorage.setItem('shops', JSON.stringify(data.data.shops))
                        AsyncStorage.setItem('company', JSON.stringify(data.data.company))
                        AsyncStorage.setItem('default_lang', data.data.company.default_lang)

                        // getBanner(uuid, token, userInfo.email).then(data => {
                        //     console.log(data)
                        //     this.setState({
                        //         logined: "logined"
                        //     })
                        // });
                        this.setState({
                            logined: "logined"
                        })
                    });
                }
            } catch (error) {
                // Error retrieving data
            }
        });
    }

    render() {
        if (this.state.result_message === 'success' && this.state.logined === 'unlogin') {
            return (
                <Redirect to={{
                    pathname: '/login',
                    state: this.state
                }}
                />
            )
        } else if (this.state.result_message === 'success' && this.state.logined === 'logined') {
            // return (
            //     <View >
            //         <Text>Auto Login Success!!</Text>
            //     </View>
            // )
            return (
                <Redirect to={{
                    // pathname: '/productlist',
                    pathname: '/selectAction',
                }}
                />
            )
        } else {
            return (
                <View >

                </View>
            );
        }
    }
}

async function getBanner(uuid, token, email) {
    try {
        return fetch(path + 'banners', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            _body: JSON.stringify({
                version: '1',
                device: 'React Native',
                os_version: '1',
                platform: 'ios',
                uuid: uuid,
                token: token,
                username: email,
            }),
            get body() {
                return this._body;
            },
            set body(value) {
                this._body = value;
            },
        })
            .then((response) => response.json())
        // return responseJson.data.payment_methods;
    }
    catch (error) {
        console.error(error);
    }
}

async function loginByToken(uuid, token, email) {
    try {
        return fetch(path + 'loginByToken', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            _body: JSON.stringify({
                version: '1',
                device: 'React Native',
                os_version: '1',
                platform: 'ios',
                uuid: uuid,
                token: token,
                username: email,
            }),
            get body() {
                return this._body;
            },
            set body(value) {
                this._body = value;
            },
        })
            .then((response) => response.json())
        // return responseJson.data.payment_methods;
    }
    catch (error) {
        console.error(error);
    }
}

async function checkLoginByToken(serverPath) {
    try {
        return fetch(path + 'loginByToken', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            _body: JSON.stringify({
                version: '1',
                device: 'React Native',
                os_version: '1',
                platform: 'ios',
            }),
            get body() {
                return this._body;
            },
            set body(value) {
                this._body = value;
            },
        })
            .then((response) => response.json())
        // return responseJson.data.payment_methods;
    }
    catch (error) {
        console.error(error);
    }
}

async function getAppChecking() {
    try {
        return fetch(path + 'appChecking', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            _body: JSON.stringify({
                version: '1',
                platform: 'ios',
            }),
            get body() {
                return this._body;
            },
            set body(value) {
                this._body = value;
            },
        })
            .then((response) => response.json())
        // return responseJson.data.payment_methods;
    }
    catch (error) {
        console.error(error);
    }
}

export default AppChecking;