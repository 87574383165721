import React, { Component } from 'react';
import { Image, StyleSheet, Alert, View, TextInput, Text, Platform, ActivityIndicator } from 'react-native';
import { Button } from 'react-native-elements';
import { AsyncStorage } from 'react-native';
import { Redirect } from 'react-router';
import RNPickerSelect from 'react-native-picker-select';
import path from './serverPath.js'

class SelectAction extends Component {

    constructor(props) {
        super(props);
        this.state = {
            uuid: '',
            token: '',
            username: '',
        };
    }

    async componentDidMount() {
        const uuid = await AsyncStorage.getItem('uuid');
        const token = await AsyncStorage.getItem('token');
        const user_info = await AsyncStorage.getItem('user_info');
        const userInfo = JSON.parse(user_info);

        this.setState({ uuid, token, username: userInfo.email })
    }

    onPressBack() {
        this.setState({
            select: false
        });
    }
    
    onCheckRecord() {
        this.props.history.push('/orderList');
    }

    onCreateOrder() {
        this.props.history.push('/productlist');
    }

    onLogout() {
        AsyncStorage.setItem('uuid', null);
        AsyncStorage.setItem('token', null);
        AsyncStorage.setItem('user_info', null)
        this.props.history.push('/');
    }

    onEditCompany(){
        this.props.history.push('/selectCompanyAction')
    }

    render() {
        if (this.state.uuid != '' && this.state.token != '') {
            return (
                <View style={styles.container}>
                    <Image style={styles.image} source={require('./image/hippos_banner.png')} />

                    <Text style={styles.title}>HIPPOS倉存系統</Text>
                    <View style={styles.login_border}>
                        <Button
                            style={{ marginTop: 10 }}
                            large
                            title="管理倉存 Manage Inventory"
                            onPress={this.onCreateOrder.bind(this)}
                        />
                        <Button
                            style={{ marginTop: 10 }}
                            large
                            title="查閱下單記錄 Order Record"
                            onPress={this.onCheckRecord.bind(this)}
                        />
                        <Button
                            style={{ marginTop: 10 }}
                            large
                            title="更改公司設定 Edit Company"
                            onPress={this.onEditCompany.bind(this)}
                        />
                        <Button
                            style={{ marginTop: 10 }}
                            title="登出 Logout"
                            onPress={this.onLogout.bind(this)}
                        />
                    </View>

                </View>
            );
        } else {
            return (
                <View style={[styles.container, styles.horizontal]}>
                    <ActivityIndicator size="large" color="#0000ff" />
                </View>
            )
        }
    }
}

async function getStaffs(email, uuid, token, shop_id) {
    try {
        return fetch(path + 'staffs', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            _body: JSON.stringify({
                username: email,
                uuid: uuid,
                token: token,
                push_key: "null",
                shop_id: shop_id
            }),
            get body() {
                return this._body;
            },
            set body(value) {
                this._body = value;
            },
        })
            .then((response) => response.json())
        // return responseJson.data.payment_methods;
    }
    catch (error) {
        console.error(error);
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    listedItem: {
        fontSize: 20,
        margin: 5,
    },
    title: {
        marginTop: 10,
        fontSize: 30,
    },
    input: {
        fontSize: 20,
        margin: 5,
        marginBottom: 10,
        borderWidth: 2,  // size/width of the border
        borderColor: 'lightgrey',  // color of the border
        height: 30
    },
    login_border: {
        marginTop: 10,
        width: '40%',
        borderColor: '#000',
        borderRadius: 5,
        borderWidth: 2,
        padding: 10,
    },
    image: {
        padding: 10, alignSelf: 'center',
        resizeMode: "stretch",
        flex: 1,
        width: '50%',
        maxWidth: '100%',
        height: 200,
    },
    warning_item: {
        color: 'red',
        fontSize: 20,
        margin: 5,
    },
});

const pickerSelectStyles = StyleSheet.create({
    inputIOS: {
        height: 40,
        paddingLeft: 10,
        borderWidth: 2,
        borderColor: '#4189D6',
        borderRadius: 4,
        color: 'black',
        paddingRight: 30, // to ensure the text is never behind the icon
    },
    inputAndroid: {
        height: 40,
        paddingLeft: 10,
        borderWidth: 2,
        borderColor: '#4189D6',
        borderRadius: 8,
        color: 'black',
        paddingRight: 30, // to ensure the text is never behind the icon
    }
});

export default SelectAction;