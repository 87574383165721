import React, { Component } from 'react';
import { Image, StyleSheet, View, Text, FlatList, TouchableOpacity } from 'react-native';
import { ThemeProvider, Button } from 'react-native-elements';
import { AsyncStorage } from 'react-native';
import path from './serverPath.js'


class DeliveryCostList extends Component {


  handlePress = order => {
    this.props.history.push('/editDeliveryCost', order);
  };

  constructor(props) {
    super(props);
    this.state = {
      delivery_costs: null,
      uuid: '',
      token: '',
      username: '',
    };
  }

  async componentDidMount() {
    const uuid = await AsyncStorage.getItem('uuid');
    const token = await AsyncStorage.getItem('token');
    const user_info = await AsyncStorage.getItem('user_info');
    const userInfo = JSON.parse(user_info);

    deliveryCosts(uuid, token, userInfo.email).then(data => {
      console.log(data)
      var delivery_costs = data.data.delivery_costs

      this.setState({
        uuid,
        token,
        username: userInfo.email,
        delivery_costs
      });
    });
  }

  onPressBack = () => {
    this.props.history.push('/selectCompanyAction');
  }

  render() {
    return (

      <View >
        <View style={{ flexDirection: 'row', width: "100%" }}>
          <Image style={styles.image} source={require('./image/hippos_banner.png')} />
          <View style={{ marginLeft: 20, padding: 10, flexDirection: 'column-reverse' }}>
            <Button
              large
              title="返回 Back"
              onPress={this.onPressBack.bind(this)}
            />
          </View>
        </View>

        <View style={styles.border}>
          <FlatList
            contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
            data={this.state.delivery_costs}
            showsVerticalScrollIndicator={false}
            extraData={this.state}
            renderItem={({ item }) => {
              if (item.operator2 == null) {
                return (
                  <TouchableOpacity onPress={() => this.handlePress(item)}>
                    <View style={styles.list_border_w_color}>
                      <View>
                        <Text style={styles.listedItemBold}>{`運費成本編號 Delivery Cost Id : ` + item.delivery_cost_id}</Text>
                        <Text style={styles.listedItem}>{`運算符 Operator (1) : ` + item.operator1}</Text>
                        <Text style={styles.listedItem}>{`數值 Operand (1) : ` + item.operand1}</Text>
                        <Text style={styles.listedItem}>{`成本 Cost : ` + item.cost}</Text>

                      </View>
                    </View>
                  </TouchableOpacity>
                );
              } else {
                return (
                  <TouchableOpacity onPress={() => this.handlePress(item)}>
                    <View style={styles.list_border_w_color}>
                      <View>
                        <Text style={styles.listedItemBold}>{`運費成本編號 Delivery Cost Id : ` + item.delivery_cost_id}</Text>
                        <Text style={styles.listedItem}>{`運算符 Operator (1) : ` + item.operator1}</Text>
                        <Text style={styles.listedItem}>{`數值 Operand (1) : ` + item.operand1}</Text>
                        <Text style={styles.listedItem}>{`運算符 Operator (2) : ` + item.operator2}</Text>
                        <Text style={styles.listedItem}>{`數值 Operand (2) : ` + item.operand2}</Text>
                        <Text style={styles.listedItem}>{`成本 Cost : ` + item.cost}</Text>
                      </View>
                    </View>
                  </TouchableOpacity>
                );
              }
            }}
            keyExtractor={item => item.delivery_cost_id.toString()}
          />
        </View>
      </View >
    );
  }
}

async function deliveryCosts(uuid, token, email) {
  try {
    return fetch(path + 'deliveryCosts', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      _body: JSON.stringify({
        version: '1',
        platform: 'ios',
        uuid: uuid,
        token: token,
        username: email,
      }),
      get body() {
        return this._body;
      },
      set body(value) {
        this._body = value;
      },
    })
      .then((response) => response.json())
    // return responseJson.data.payment_methods;
  }
  catch (error) {
    console.error(error);
  }
}

const styles = StyleSheet.create({
  listedItem: {
    fontSize: 16,
    // background: '#285b97',
    borderRadius: 3,
    padding: 5,
    margin: 5,
  },
  listedItemComplete: {
    fontSize: 16,
    color: '#62C377',
    // background: '#285b97',
    borderRadius: 3,
    padding: 5,
    margin: 5,
  },
  listedItemCancel: {
    fontSize: 16,
    color: 'red',
    // background: '#285b97',
    borderRadius: 3,
    padding: 5,
    margin: 5,
  },
  listedItemPending: {
    fontSize: 16,
    color: '#59A0E8',
    // background: '#285b97',
    borderRadius: 3,
    padding: 5,
    margin: 5,
  },
  listedItemBold: {
    fontWeight: "bold",
    fontSize: 18,
    // background: '#285b97',
    borderRadius: 3,
    padding: 5,
    margin: 5,
  },
  listedPageNum: {
    fontSize: 18,
    marginRight: 5,
    textDecorationLine: 'underline',
    color: 'blue'
  },
  selectedPageNum: {
    fontSize: 20,
    marginRight: 5,
    color: 'Black'
  },
  image: {
    resizeMode: "stretch",
    width: '30%',
    height: 100,
  }, border: {
    marginTop: 10,
    width: '100%',
    borderColor: '#000',
    borderRadius: 5,
    borderWidth: 2,
    padding: 10,
  },
  cover: {
    resizeMode: "stretch",
    flex: 1,
    width: 120,
    height: 100,
  },
  list_border: {
    marginTop: 10,
    width: '100%',
    borderColor: '#000',
    borderRadius: 5,
    borderWidth: 2,
    padding: 10,
    flexGrow: 1,
    flexDirection: 'row'
  },
  list_border_w_color: {
    marginTop: 10,
    width: '100%',
    borderColor: '#000',
    borderRadius: 5,
    borderWidth: 2,
    padding: 10,
    flexGrow: 1,
    flexDirection: 'row'
  }
});


export default DeliveryCostList;