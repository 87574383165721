import React, { Component } from 'react';
import { Image, StyleSheet, Alert, View, TextInput, Text, Platform } from 'react-native';
import { ThemeProvider, Button } from 'react-native-elements';
import { AsyncStorage } from 'react-native';
import { Redirect } from 'react-router';
import path from './serverPath.js'
import SwitchSelector from 'react-native-switch-selector';
import RNPickerSelect from 'react-native-picker-select';

class EditDeliveryCost extends Component {

    constructor(props) {
        super(props);
        this.state = {
            delivery_cost : null,
            operatorItem: [],
            operator1: '',
            operand1: '',
            cost: '',
            operator2: '',
            operand2: '',

            warning_operator1: false,
            warning_operand1: false,
            warning_cost: false,
            warning_operator2: false,
            warning_operand2: false,
        };
    }

    async onPressButton() {
        console.log("onPressButton")
        this.setState({
            warning_operator1: false,
            warning_operand1: false,
            warning_cost: false,
            warning_operator2: false,
            warning_operand2: false,
        })

        const uuid = await AsyncStorage.getItem('uuid');
        const token = await AsyncStorage.getItem('token');
        const user_info = await AsyncStorage.getItem('user_info');
        const userInfo = JSON.parse(user_info);

        console.log("operator1" , this.state.operator1)
        console.log("cost" , this.state.cost)
        console.log("operand1" , this.state.operand1)
        if (this.state.operator1 != '' && this.state.cost != '' && this.state.operand1 >= 0) {
            console.log("IF 1")
            if (this.state.operator2 === "" && this.state.operand2 != "") {
                console.log("IF 2")
                this.setState({ warning_operator2: true })
            } else if (this.state.operand2 === "" && this.state.operator2 != "") {
                console.log("IF 3")
                this.setState({ warning_operand2: true })
            } else {
                console.log("CallAPI")
                createDeliveryCost(userInfo.email, uuid, token, this.state.delivery_cost.delivery_cost_id, this.state.operator1, this.state.operand1, this.state.cost, this.state.operator2, this.state.operand2).then(data => {
                    console.log(data)
                    if (data.result == 0) {
                        this.props.history.push('/deliveryCostList')
                    }
                });
            }
        }
        console.log("IF 4")

        if (this.state.operator1 === "") {
            this.setState({ warning_operator1: true })
        }
        if (this.state.operand1 === "") {
            this.setState({ warning_operand1: true })
        }
        if (this.state.cost === "") {
            this.setState({ warning_cost: true })
        }
    }

    async componentDidMount() {
        const delivery_cost = this.props.location.state;

        var operatorItem = []
        operatorItem.push({ label: '大於Greater than (>)', value: '>' });
        operatorItem.push({ label: '大過等於Greater than or Equal to (>=)', value: '>=' });
        operatorItem.push({ label: '等於Equal to (=)', value: '=' });
        operatorItem.push({ label: '小過等於Less than or Equal to (<=)', value: '<=' });
        operatorItem.push({ label: '小於Less than (<)', value: '<' });

        this.setState({
            delivery_cost,
            operatorItem,
            operator1: delivery_cost.operator1,
            operand1: delivery_cost.operand1,
            cost: delivery_cost.cost,
        })

        if (delivery_cost.operator2 != null) {
            this.setState({
                operator2: delivery_cost.operator2,
                operand2: delivery_cost.operand2,
            })
        }
    }

    onPressBack = () => {
        this.props.history.push('/deliveryCostList');
    }

    onSelect(value) {
        console.log(value)
        if (value.includes("Select")) {
            this.setState({
                operator1: ''
            })
        } else {
            this.setState({
                operator1: value
            })
        }
    }

    onSelect2(value) {
        console.log(value)
        if (value.includes("Select")) {
            this.setState({
                operator2: ''
            })
        } else {
            this.setState({
                operator2: value
            })
        }
    }

    async handleRemove() {
        const uuid = await AsyncStorage.getItem('uuid');
        const token = await AsyncStorage.getItem('token');
        const user_info = await AsyncStorage.getItem('user_info');
        const userInfo = JSON.parse(user_info);

        deleteDeliveryCost(userInfo.email, uuid, token, this.state.delivery_cost.delivery_cost_id).then(data => {
            console.log(data)
            if (data.result == 0) {
                this.props.history.push('/deliveryCostList')
            }
        });
    }


    render() {
        return (
            <View style={styles.container}>
                <Image style={styles.banner} source={require('./image/hippos_banner.png')} />
                <Text style={styles.title}>編輯運費 Edit Delivery Cost</Text>
                <View style={styles.login_border}>

                    {this.state.warning_cost &&
                        <Text style={styles.warning_item}>請輸入成本 Cost</Text>}
                    {this.state.warning_operator1 &&
                        <Text style={styles.warning_item}>請選擇運算符 Operator (1)</Text>}
                    {this.state.warning_operand1 &&
                        <Text style={styles.warning_item}>請輸數值Operand (1)</Text>}
                    {this.state.warning_operator2 &&
                        <Text style={styles.warning_item}>若選擇運算符 Operator (2)，數值Operand (2)不能為空</Text>}
                    {this.state.warning_operand2 &&
                        <Text style={styles.warning_item}>若輸入數值Operand (2)，運算符 Operator (2)不能為空</Text>}

                    <Text style={styles.text}>運費 Delivery Cost</Text>
                    <TextInput
                        style={styles.inputfield}
                        placeholder="運費 Delivery Cost"
                        onChangeText={(text) => { this.setState({ cost: text }) }}
                        value={`${this.state.cost}`}
                    />

                    <Text style={styles.text}>運算符 Operator (1)</Text>
                    <RNPickerSelect
                        style={{ ...pickerSelectStyles }}
                        value={this.state.operator1 || ''}
                        onValueChange={this.onSelect.bind(this)}
                        // onValueChange={(value) => console.log(value)}
                        items={this.state.operatorItem}
                    />

                    <Text style={styles.text}>數值Operand (1)</Text>
                    <TextInput
                        style={styles.inputfield}
                        placeholder="數值 Operand"
                        onChangeText={(text) => { this.setState({ operand1: text }) }}
                        value={`${this.state.operand1}`}
                    />

                    <Text style={styles.text}>運算符 Operator (2)（選填）</Text>
                    <RNPickerSelect
                        style={{ ...pickerSelectStyles }}
                        value={this.state.operator2 || ''}
                        onValueChange={this.onSelect2.bind(this)}
                        // onValueChange={(value) => console.log(value)}
                        items={this.state.operatorItem}
                    />

                    <Text style={styles.text}>數值Operand (2)（選填）</Text>
                    <TextInput
                        style={styles.inputfield}
                        placeholder="數值 Operand（2）（選填）"
                        onChangeText={(text) => { this.setState({ operand2: text }) }}
                        value={`${this.state.operand2}`}
                    />

                    <Button
                        style={{
                            marginTop: 10
                        }}
                        large
                        title="更新 Update"
                        onPress={this.onPressButton.bind(this)}
                    />


                    <ThemeProvider theme={theme} >
                        <Button title='刪除 Remove'
                            style={{
                                marginTop: 10
                            }}
                            onPress={() => this.handleRemove(this)} />
                    </ThemeProvider>


                    <Button
                        style={{ marginTop: 10 }}
                        large
                        title="返回 Back"
                        onPress={this.onPressBack.bind(this)}
                    />

                </View>

            </View >
        );
    }
}

async function createDeliveryCost(email, uuid, token, delivery_cost_id, operator1, operand1, cost, operator2, operand2) {

    const data = new FormData();

    data.append('username', email);
    data.append('uuid', uuid);
    data.append('token', token);
    data.append('delivery_cost_id', delivery_cost_id);
    data.append('operator1', operator1);
    data.append('operand1', operand1);
    data.append('cost', cost);
    if (operator2 != '' || operand2 != '') {
        data.append('operator2', operator2);
        data.append('operand2', operand2);
    }

    try {
        return fetch(path + 'updateDeliveryCost', {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            _body: data,
            get body() {
                return this._body;
            },
            set body(value) {
                this._body = value;
            },
        })
            .then((response) => response.json())
    }
    catch (error) {
        console.error(error);
    }
}

async function deleteDeliveryCost(email, uuid, token, delivery_cost_id) {

    const data = new FormData();

    data.append('username', email);
    data.append('uuid', uuid);
    data.append('token', token);
    data.append('delivery_cost_id', delivery_cost_id);

    try {
        return fetch(path + 'deleteDeliveryCost', {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            _body: data,
            get body() {
                return this._body;
            },
            set body(value) {
                this._body = value;
            },
        })
            .then((response) => response.json())
    }
    catch (error) {
        console.error(error);
    }
}

const theme = {
    colors: {
        primary: 'red',
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    listedItem: {
        fontSize: 20,
        margin: 5,
    },
    title: {
        marginTop: 10,
        fontSize: 30,
    },
    input: {
        fontSize: 20,
        margin: 5,
        marginBottom: 10,
        borderWidth: 2,  // size/width of the border
        borderColor: 'lightgrey',  // color of the border
        height: 30
    },
    login_border: {
        marginTop: 10,
        width: '70%',
        borderColor: '#000',
        borderRadius: 5,
        borderWidth: 2,
        padding: 10,
    },
    image: {
        padding: 10,
        alignSelf: 'center',
        resizeMode: 'contain',
        width: '100%',
        height: 200,
    },
    banner: {
        padding: 10, alignSelf: 'center',
        resizeMode: "contain",
        flex: 1,
        width: '50%',
        maxWidth: '100%',
        height: 200,
    },
    text: {
        marginTop: 10,
        marginBottom: 2,
        fontSize: 20,
    },
    inputfield: {
        borderRadius: 8,
        borderWidth: 2,
        color: 'black',
        borderColor: '#4189D6',
        paddingLeft: 10,
        fontSize: 20,
        height: 40
    },
    warning_item: {
        color: 'red',
        fontSize: 20,
        margin: 5,
    },
});

const pickerSelectStyles = StyleSheet.create({
    inputIOS: {
        height: 40,
        paddingLeft: 10,
        borderWidth: 2,
        borderColor: '#4189D6',
        borderRadius: 4,
        color: 'black',
        paddingRight: 30, // to ensure the text is never behind the icon
    },
    inputAndroid: {
        height: 40,
        paddingLeft: 10,
        borderWidth: 2,
        borderColor: '#4189D6',
        borderRadius: 8,
        color: 'black',
        paddingRight: 30, // to ensure the text is never behind the icon
    }
});

export default EditDeliveryCost;