import React, { Component } from 'react';
import { View, StyleSheet } from 'react-native';
import { Router, Switch, Route } from './routing';
import PaymentMethod from './PaymentMethod';
import ShowPayment from './ShowPayment';
import Login from './Login';
import AppChecking from './AppChecking';
import Productlist from './ProductList';
import ShowProduct from './ShowProduct';
import CreateProduct from './CreateProduct';
import EditProduct from './EditProduct';
import CreateCategory from './CreateCategory';
import Register from './Register'
import StockIn from './StockIn'
import StockOutList from './StockOutList'
import StockOut from './StockOut'
import SelectAction from './SelectAction'
import OrderList from './OrderList'
import OrderDetail from './OrderDetail'
import CreateBanner from './CreateBanner'
import SelectCompanyAction from './SelectCompanyAction'
import BannerList from './BannerList'
import EditBanner from './EditBanner'
import EditCompany from './EditCompany'
import CategoryList from './CategoryList'
import EditCategory from './EditCategory'
import StockInRecord from './StockInRecord'
import EditStockIn from './EditStockIn'
import CreateDeliveryCost from './CreateDeliveryCost'
import DeliveryCostList from './DeliveryCostList'
import EditDeliveryCost from './EditDeliveryCost'
import CreateBrand from './CreateBrand'
import BrandList from './BrandList'
import EditBrand from './EditBrand'

class App extends Component {

  async componentDidMount() {
   
  }

  render() {
    return (
      <View style={styles.container}>
        <Router>
          <Switch>
            {/* <Route
              exact
              path="/"
              render={props => (
                <PaymentMethod 
                {...props} 
                />
              )}
            />
            <Route
              path="/showPayment"
              render={props => (
                <ShowPayment
                  {...props}
                />
              )}
            /> */}
            <Route
              exact
              path="/"
              render={props => (
                <AppChecking
                  {...props}
                />
              )}
            />
            <Route
              path="/login"
              render={props => (
                <Login
                  {...props}
                />
              )}
            />
            <Route
              path="/productlist"
              render={props => (
                <Productlist
                  {...props}
                />
              )}
            />
            <Route
              path="/showProduct"
              render={props => (
                <ShowProduct
                  {...props}
                />
              )}
            />
            <Route
              path="/createProduct"
              render={props => (
                <CreateProduct
                  {...props}
                />
              )}
            />
            <Route
              path="/editProduct"
              render={props => (
                <EditProduct
                  {...props}
                />
              )}
            />
            <Route
              path="/createCategory"
              render={props => (
                <CreateCategory
                  {...props}
                />
              )}
            />
            <Route
              path="/register"
              render={props => (
                <Register
                  {...props}
                />
              )}
            />
            <Route
              path="/stockIn"
              render={props => (
                <StockIn
                  {...props}
                />
              )}
            />
            <Route
              path="/stockOutList"
              render={props => (
                <StockOutList
                  {...props}
                />
              )}
            />
            <Route
              path="/stockOut"
              render={props => (
                <StockOut
                  {...props}
                />
              )}
            />
            <Route
              path="/selectAction"
              render={props => (
                <SelectAction
                  {...props}
                />
              )}
            />
            <Route
              path="/orderList"
              render={props => (
                <OrderList
                  {...props}
                />
              )}
            />
            <Route
              path="/orderDetail"
              render={props => (
                <OrderDetail
                  {...props}
                />
              )}
            />
            <Route
              path="/createBanner"
              render={props => (
                <CreateBanner
                  {...props}
                />
              )}
            />
            <Route
              path="/selectCompanyAction"
              render={props => (
                <SelectCompanyAction
                  {...props}
                />
              )}
            />
            <Route
              path="/bannerList"
              render={props => (
                <BannerList
                  {...props}
                />
              )}
            />
            <Route
              path="/editBanner"
              render={props => (
                <EditBanner
                  {...props}
                />
              )}
            />
            <Route
              path="/editCompany"
              render={props => (
                <EditCompany
                  {...props}
                />
              )}
            />
            <Route
              path="/categoryList"
              render={props => (
                <CategoryList
                  {...props}
                />
              )}
            />
            <Route
              path="/editCategory"
              render={props => (
                <EditCategory
                  {...props}
                />
              )}
            />
            <Route
              path="/stockInRecord"
              render={props => (
                <StockInRecord
                  {...props}
                />
              )}
            />
            <Route
              path="/editStockIn"
              render={props => (
                <EditStockIn
                  {...props}
                />
              )}
            />
            <Route
              path="/createDeliveryCost"
              render={props => (
                <CreateDeliveryCost
                  {...props}
                />
              )}
            />
            <Route
              path="/deliveryCostList"
              render={props => (
                <DeliveryCostList
                  {...props}
                />
              )}
            />
            <Route
              path="/editDeliveryCost"
              render={props => (
                <EditDeliveryCost
                  {...props}
                />
              )}
            />
            <Route
              path="/createBrand"
              render={props => (
                <CreateBrand
                  {...props}
                />
              )}
            />
             <Route
              path="/brandList"
              render={props => (
                <BrandList
                  {...props}
                />
              )}
            />
             <Route
              path="/editBrand"
              render={props => (
                <EditBrand
                  {...props}
                />
              )}
            />
          </Switch>
        </Router>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingTop: '5%',
    paddingLeft: '10%',
    paddingRight: '10%',
    justifyContent: 'center',
  }
});

export default App;