import React, { Component } from 'react';
import { Image, StyleSheet, View, Text, FlatList, TouchableOpacity, ActivityIndicator } from 'react-native';
import { ThemeProvider, Button } from 'react-native-elements';
import { AsyncStorage } from 'react-native';
import RNPickerSelect from 'react-native-picker-select';
import path from './serverPath.js'
import { FlatGrid } from 'react-native-super-grid';


class CategoryList extends Component {


  handlePress = category => {
    this.props.history.push('/editCategory', category);
  };

  constructor(props) {
    super(props);
    this.state = {
      categories: null,
      uuid: '',
      token: '',
      username: '',
    };
  }

  onCreateCategory() {
    this.props.history.push('/createCategory');
  }


  async componentDidMount() {

    const uuid = await AsyncStorage.getItem('uuid');
    const token = await AsyncStorage.getItem('token');
    const user_info = await AsyncStorage.getItem('user_info');
    const userInfo = JSON.parse(user_info);

    getCategories(uuid, token, userInfo.email).then(data => {
      console.log(data);
      var categories = data.data.categories
      this.setState({
        categories,
        uuid,
        token,
        username: userInfo.email,
      });
    });
  }

  onPressBack = () => {
    this.props.history.push('/productList');
  }

  onLogout() {
    AsyncStorage.setItem('uuid', null);
    AsyncStorage.setItem('token', null);
    AsyncStorage.setItem('user_info', null)
    this.props.history.push('/');
  }

  render() {
    if (this.state.categories == null) {
      return (
        <View style={[styles.container, styles.horizontal]}>
          <ActivityIndicator size="large" color="#0000ff" />
        </View>
      )
    } else {
      return (
        <View >
          <View style={{ flexDirection: 'row', width: "100%" }}>
            <Image style={styles.image} source={require('./image/hippos_banner.png')} />
            <View style={{ marginLeft: 20, padding: 10, flexDirection: 'column-reverse' }}>
              <View style={{
                // alignItems: "center",
                width: '100%',
                marginTop: 10,
                marginBottom: 10,
                flexDirection: 'row'
              }}>
                <Button
                  style={{ marginTop: 10 }}
                  large
                  title="返回 Back"
                  onPress={this.onPressBack.bind(this)}
                />
                <Button
                style={{ marginTop: 10 , marginLeft: 10}}
                  title="新增分類 Create Category"
                  onPress={this.onCreateCategory.bind(this)}
                />
              </View>
            </View>
          </View>

          <View >
            <FlatGrid
              itemDimension={200}
              items={this.state.categories}
              renderItem={({ item }) =>
                <TouchableOpacity onPress={() => this.handlePress(item)}>
                  <View style={styles.list_border}>
                    <View>
                      <Text style={styles.listedItemName}>{item.name_en}</Text>
                    </View>
                  </View>
                </TouchableOpacity>
              }
              keyExtractor={item => item.id}
            />
          </View>
        </View >
      );
    }
  }
}

async function getshops(uuid, token, email) {
  try {
    return fetch(path + 'inventory/shops', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      _body: JSON.stringify({
        version: '1',
        platform: 'ios',
        uuid: uuid,
        token: token,
        username: email,
      }),
      get body() {
        return this._body;
      },
      set body(value) {
        this._body = value;
      },
    })
      .then((response) => response.json())
    // return responseJson.data.payment_methods;
  }
  catch (error) {
    console.error(error);
  }
}


async function getCategories(uuid, token, email) {
  try {
    return fetch(path + 'inventory/categories', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      _body: JSON.stringify({
        version: '1',
        platform: 'ios',
        uuid: uuid,
        token: token,
        username: email,
        all_langs: 'yes',
      }),
      get body() {
        return this._body;
      },
      set body(value) {
        this._body = value;
      },
    })
      .then((response) => response.json())
    // return responseJson.data.payment_methods;
  }
  catch (error) {
    console.error(error);
  }
}

const styles = StyleSheet.create({
  listedItem: {
    fontSize: 14,
    // background: '#285b97',
    borderRadius: 3,
    padding: 5,
    marginLeft: 2,
  },
  listedItemName: {
    fontSize: 16,
    // background: '#285b97',
    flex: 1,
    alignSelf: 'center',
    borderRadius: 3,
    padding: 5,
    marginLeft: 2,
  },
  listedPageNum: {
    fontSize: 18,
    marginRight: 5,
    textDecorationLine: 'underline',
    color: 'blue'
  },
  listedItemCancel: {
    fontSize: 14,
    color: 'red',
    borderRadius: 3,
    padding: 5,
    marginLeft: 2,
  },
  listedItemComplete: {
    fontSize: 14,
    color: '#62C377',
    // background: '#285b97',
    borderRadius: 3,
    padding: 5,
    marginLeft: 2,
  },
  image: {
    resizeMode: "stretch",
    width: '30%',
    height: 100,
  }, border: {
    marginTop: 10,
    width: '100%',
    borderColor: '#000',
    borderRadius: 5,
    borderWidth: 2,
    padding: 10,
  },
  cover: {
    resizeMode: "stretch",
    flex: 1,
    width: 100,
    height: 100,
  },
  list_border: {
    marginTop: 10,
    width: '100%',
    borderColor: '#000',
    borderRadius: 5,
    borderWidth: 2,
    padding: 10,
    flexGrow: 1,
    flexDirection: 'row'
  }
});

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    height: 40,
    paddingLeft: 10,
    borderWidth: 2,
    borderColor: '#4189D6',
    borderRadius: 4,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
  },
  inputAndroid: {
    height: 40,
    paddingLeft: 10,
    borderWidth: 2,
    borderColor: '#4189D6',
    borderRadius: 8,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
  }
});



export default CategoryList
  ;