import React, { Component } from 'react';
import { Image, StyleSheet, Alert, View, TextInput, Text, Platform } from 'react-native';
import { Button } from 'react-native-elements';
import { AsyncStorage } from 'react-native';
import { Redirect } from 'react-router';
import path from './serverPath.js'

class CreateCategory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name_en: "",
            name_zh_TW: "",
            name_zh_CN: "",
            all_langs: "yes",
        };
    }

    async onPressButton() {
        const uuid = await AsyncStorage.getItem('uuid');
        const token = await AsyncStorage.getItem('token');
        const user_info = await AsyncStorage.getItem('user_info');
        const userInfo = JSON.parse(user_info);

        createCategory(userInfo.email, uuid, token, this.state.name_en, this.state.name_zh_TW, this.state.name_zh_CN, this.state.all_langs).then(data => {

            getCategory(userInfo.email, uuid, token).then(data => {
                AsyncStorage.setItem('categories', JSON.stringify(data.data.categories))
                this.props.history.push('/productlist');
            });
        });
    }


    async componentDidMount() {

    }

    onPressBack = () => {
        this.props.history.push('/categoryList');
    }


    render() {
        return (
            <View style={styles.container}>
                <Image style={styles.image} source={require('./image/hippos_banner.png')} />
                <Text style={styles.title}>新增分類Create Category</Text>
                <View style={styles.login_border}>
                    <Text style={styles.listedItem}>分類名稱Category Name(EN)</Text>
                    <TextInput
                        style={styles.input}
                        textContentType="none"
                        placeholder="Category Name(EN)"
                        onChangeText={(text) => { this.setState({ name_en: text }) }}
                        value={this.state.name_en}
                    />
                    <Text style={styles.listedItem}>分類名稱Category Name(繁體)</Text>
                    <TextInput
                        style={styles.input}
                        textContentType="none"
                        placeholder="分類名稱(繁體)"
                        onChangeText={(text) => { this.setState({ name_zh_TW: text }) }}
                        value={this.state.name_zh_TW}
                    />
                    <Text style={styles.listedItem}>分類名稱Category Name(簡體)</Text>
                    <TextInput
                        style={styles.input}
                        textContentType="none"
                        placeholder="分類名稱(簡體)"
                        onChangeText={(text) => { this.setState({ name_zh_CN: text }) }}
                        value={this.state.name_zh_CN}
                    />
                    <Button
                        large
                        title="創建 Create"
                        onPress={this.onPressButton.bind(this)}
                    />

                    <Button
                        style={{ marginTop: 10 }}
                        large
                        title="返回 Back"
                        onPress={this.onPressBack.bind(this)}
                    />

                </View>

            </View>
        );
    }
}

async function createCategory(email, uuid, token, name_en, name_zh_TW, name_zh_CN, all_langs) {
    try {
        return fetch(path + 'inventory/createCategory', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            _body: JSON.stringify({
                email: email,
                version: '1',
                device: 'React Native',
                os_version: '1',
                platform: 'ios',
                username: email,
                uuid: uuid,
                token: token,
                push_key: "null",
                all_langs: all_langs,
                name_en: name_en,
                name_zh_TW: name_zh_TW,
                name_zh_CN: name_zh_CN,
            }),
            get body() {
                return this._body;
            },
            set body(value) {
                this._body = value;
            },
        })
            .then((response) => response.json())
        // return responseJson.data.payment_methods;
    }
    catch (error) {
        console.error(error);
    }
}

async function getCategory(email, uuid, token) {
    try {
        return fetch(path + 'inventory/categories', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            _body: JSON.stringify({
                email: email,
                version: '1',
                device: 'React Native',
                os_version: '1',
                platform: 'ios',
                username: email,
                uuid: uuid,
                token: token,
                push_key: "null",
            }),
            get body() {
                return this._body;
            },
            set body(value) {
                this._body = value;
            },
        })
            .then((response) => response.json())
        // return responseJson.data.payment_methods;
    }
    catch (error) {
        console.error(error);
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    listedItem: {
        fontSize: 20,
        margin: 5,
    },
    title: {
        marginTop: 10,
        fontSize: 30,
    },
    input: {
        fontSize: 20,
        margin: 5,
        marginBottom: 10,
        borderWidth: 2,  // size/width of the border
        borderColor: 'lightgrey',  // color of the border
        height: 30
    },
    login_border: {
        marginTop: 10,
        width: '40%',
        borderColor: '#000',
        borderRadius: 5,
        borderWidth: 2,
        padding: 10,
    },
    image: {
        padding: 10, alignSelf: 'center',
        resizeMode: "stretch",
        flex: 1,
        width: '50%',
        maxWidth: '100%',
        height: 200,
    }
});

export default CreateCategory;