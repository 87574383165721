import React, { Component } from 'react';
import { Image, StyleSheet, Alert, View, TextInput, Text, Platform, ActivityIndicator } from 'react-native';
import { ThemeProvider, Button } from 'react-native-elements';
import { AsyncStorage } from 'react-native';
import { Redirect } from 'react-router';
import path from './serverPath.js'
import imagePath from './storagePath.js'
import SwitchSelector from 'react-native-switch-selector';
import CheckboxFormX from 'react-native-checkbox-form';
import ImagePicker from "react-native-everywhere-image-picker";

class EditCompany extends Component {

    constructor(props) {
        super(props);
        this.state = {
            company: null,
            langOptions: null,
            langArray: null,
            default_lang_options: [],
            default_lang_position: 0,
            default_lang: '',
            name: '',

            previewImage: '',
            image: '',
        };
    }

    async onPressButton() {
        const uuid = await AsyncStorage.getItem('uuid');
        const token = await AsyncStorage.getItem('token');
        const user_info = await AsyncStorage.getItem('user_info');
        const userInfo = JSON.parse(user_info);

        console.log(this.state)

        updateCompanyInfo(userInfo.email, uuid, token, this.state.name, this.state.langArray, this.state.default_lang, this.state.image).then(data => {
            console.log(data)
            if (data.result == 0) {
                AsyncStorage.setItem('company', JSON.stringify(data.data.company))
                this.props.history.push('/selectCompanyAction')
            }
        });
    }


    async componentDidMount() {
        var company = JSON.parse(await AsyncStorage.getItem('company'));

        var langOptions = [];
        langOptions.push({ label: '英文English', value: 'en', RNchecked: false });
        langOptions.push({ label: '繁體中文Traditional', value: 'zh_TW', RNchecked: false });
        langOptions.push({ label: '简体中文Simplified', value: 'zh_CN', RNchecked: false });

        var langs = company.langs

        for (let lang of langs) {
            for (var i = 0; i < langOptions.length; i++) {
                if (langOptions[i].value == lang) {
                    langOptions[i].RNchecked = true;
                }
            }
        }

        var name = company.name

        const default_lang_options = [
            { label: "英文English", value: 'en' },
            { label: "繁體中文Traditional", value: 'zh_TW' },
            { label: "简体中文Simplified", value: 'zh_CN' },
        ];

        var default_lang = company.default_lang
        var default_lang_position = 0

        if (default_lang === 'en') {
            default_lang_position = 0
        } else if (default_lang === 'zh_TW') {
            default_lang_position = 1
        } else {
            default_lang_position = 2
        }

        var previewImage = company.logo

        this.setState({
            company,
            langOptions,
            langArray: langs,
            name,
            default_lang_options,
            default_lang_position,
            default_lang,
            previewImage
        })
    }

    onPressBack = () => {
        this.props.history.push('/selectCompanyAction');
    }

    _handleImage = ({ uri }) => {

        var startIndex = uri.indexOf("base64,") + 7;
        var b64 = uri.substr(startIndex);
        var byteCharacters = atob(b64);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var image = new Blob([byteArray], { type: 'image/png' });

        this.setState(() => ({ image, previewImage: uri }));

    };

    switchLangSelected(value) {
        if (value == 'en') {
            this.setState({ default_lang: 'en', default_lang_position: 0 })
        } else if (value == 'zh_TW') {
            this.setState({ default_lang: 'zh_TW', default_lang_position: 1 })
        } else {
            this.setState({ default_lang: 'zh_CN', default_lang_position: 2 })
        }
    }

    _onLangSelect = (items) => {
        var langArray = [];
        for (let item of items) {
            if (item.RNchecked) {
                langArray.push(item.value);
            }
        }
        console.log(langArray)
        this.setState({
            langArray
        })
    };

    handleRemoveImage() {
        this.setState({
            image: '', previewImage: this.state.company.logo
        })
    }

    render() {
        if (this.state.company != null) {
            return (
                <View style={styles.container}>
                    <Image style={styles.banner} source={require('./image/hippos_banner.png')} />
                    <Text style={styles.title}>編輯公司設定 Edit Company Info</Text>
                    <View style={styles.login_border}>

                        <Text style={styles.text}>公司名稱 Company Name</Text>
                        <TextInput
                            style={styles.inputfield}
                            textContentType="text"
                            placeholder="名稱Name(EN)"
                            onChangeText={(text) => { this.setState({ name: text }) }}
                            value={`${this.state.name}`}
                        />

                        {/* <Text style={styles.text}>語言 Language</Text>
                        <CheckboxFormX
                            style={{ width: '100%' }}
                            textStyle={{ marginRight: 20, fontSize: 20 }}
                            dataSource={this.state.langOptions}
                            itemShowKey="label"
                            itemCheckedKey="RNchecked"
                            iconSize={30}
                            formHorizontal={true}
                            labelHorizontal={true}
                            onChecked={(item) => this._onLangSelect(item)}
                        /> */}

                        <Text style={styles.text}>預設語言 Default Language</Text>
                        <View style={{ width: '60%', borderRadius: 50, borderColor: '#4189D6', borderWidth: 2 }}>
                            <SwitchSelector
                                options={this.state.default_lang_options}
                                initial={this.state.default_lang_position}
                                buttonColor={'#4189D6'}
                                onPress={(value) => this.switchLangSelected(value)}
                            />
                        </View>

                        <Text style={styles.text}>公司標誌 Company Logo</Text>
                        <ImagePicker onComplete={this._handleImage}>
                            <Button
                                style={{ marginTop: 20, marginBottom: 20 }}
                                large
                                title="更換標誌 Change Logo"
                            />
                        </ImagePicker>
                        <Image source={{ uri: this.state.previewImage }} style={styles.image} />
                        {this.state.image != '' &&
                            <ThemeProvider theme={theme} >
                                <Button title='取消 Cancel'
                                    onPress={() => this.handleRemoveImage(this)} />
                            </ThemeProvider>}

                        <Button
                            large
                            style={{ paddingTop: 10, paddingBottom: 10 }}
                            title="更新 Update"
                            onPress={this.onPressButton.bind(this)}
                        />

                        <Button
                            style={{ marginTop: 10 }}
                            large
                            title="返回 Back"
                            onPress={this.onPressBack.bind(this)}
                        />

                    </View>

                </View>
            );
        } else {
            return (
                <View style={[styles.container, styles.horizontal]}>
                    <ActivityIndicator size="large" color="#0000ff" />
                </View>
            )
        }
    }
}

async function updateCompanyInfo(email, uuid, token, name, langs, default_lang, logo) {

    const data = new FormData();

    data.append('username', email);
    data.append('uuid', uuid);
    data.append('token', token);
    data.append('name', name);
    data.append('langs', `[` + langs + `]`);
    data.append('default_lang', default_lang);
    if (logo != '') {
        data.append('logo', logo);
    }

    try {
        return fetch(path + 'updateCompanyInfo', {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            _body: data,
            get body() {
                return this._body;
            },
            set body(value) {
                this._body = value;
            },
        })
            .then((response) => response.json())
    }
    catch (error) {
        console.error(error);
    }
}

const theme = {
    colors: {
        primary: 'red',
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    listedItem: {
        fontSize: 20,
        margin: 5,
    },
    title: {
        marginTop: 10,
        fontSize: 30,
    },
    input: {
        fontSize: 20,
        margin: 5,
        marginBottom: 10,
        borderWidth: 2,  // size/width of the border
        borderColor: 'lightgrey',  // color of the border
        height: 30
    },
    login_border: {
        marginTop: 10,
        width: '70%',
        borderColor: '#000',
        borderRadius: 5,
        borderWidth: 2,
        padding: 10,
    },
    image: {
        padding: 10,
        alignSelf: 'center',
        resizeMode: 'contain',
        width: '100%',
        height: 200,
    },
    banner: {
        padding: 10, alignSelf: 'center',
        resizeMode: "contain",
        flex: 1,
        width: '50%',
        maxWidth: '100%',
        height: 200,
    },
    text: {
        marginTop: 10,
        marginBottom: 2,
        fontSize: 20,
    },
    inputfield: {
        borderRadius: 8,
        borderWidth: 2,
        color: 'black',
        borderColor: '#4189D6',
        paddingLeft: 10,
        fontSize: 20,
        height: 40
    },
});

export default EditCompany;