import React, { Component } from 'react';
import { Image, StyleSheet, View, Text, FlatList, TouchableOpacity, ActivityIndicator } from 'react-native';
import { ThemeProvider, Button } from 'react-native-elements';
import { AsyncStorage } from 'react-native';
import RNPickerSelect from 'react-native-picker-select';
import path from './serverPath.js'
import storagePath from './storagePath.js'
import { FlatGrid } from 'react-native-super-grid';


class BrandList extends Component {

  handlePress = banner => {
    this.props.history.push('/editBrand', banner);
  };

  constructor(props) {
    super(props);
    this.state = {
      brands: null,
      uuid: '',
      token: '',
      username: '',
    };
  }

  async componentDidMount() {

    const uuid = await AsyncStorage.getItem('uuid');
    const token = await AsyncStorage.getItem('token');
    const user_info = await AsyncStorage.getItem('user_info');
    const userInfo = JSON.parse(user_info);

    this.setState({
      uuid,
      token,
      username: userInfo.email,
    })

    getBrands(uuid, token, userInfo.email).then(data => {
      console.log(data)
      var brands = data.data.brands

      this.setState({
        brands
      })
    })
  }

  onPressBack = () => {
    this.props.history.push('/selectCompanyAction');
  }

  render() {
    if (this.state.brands == null) {
      return (
        <View style={[styles.container, styles.horizontal]}>
          <ActivityIndicator size="large" color="#0000ff" />
        </View>
      )
    } else {
      return (
        <View >
          <View style={{ flexDirection: 'row', width: "100%" }}>
            <Image style={styles.banner} source={require('./image/hippos_banner.png')} />
            <Button
              style={{ marginTop: 10 }}
              large
              title="返回 Back"
              onPress={this.onPressBack.bind(this)}
            />

          </View>
          <View >
            <FlatGrid
              itemDimension={200}
              items={this.state.brands}
              renderItem={({ item }) => {
                return (
                  <TouchableOpacity onPress={() => this.handlePress(item)}>
                    <View style={styles.list_border}>
                      <View>
                        <Image source={item.logo} style={styles.image} />
                        <Text style={styles.listedItem}>{item.name_en}</Text>
                      </View>
                    </View>
                  </TouchableOpacity>
                )
              }}
              keyExtractor={item => item.id}
            />
          </View>
        </View>
      )
    }
  }
}

async function getBrands(uuid, token, email) {
  try {
    return fetch(path + 'inventory/brands', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      _body: JSON.stringify({
        version: '1',
        platform: 'ios',
        uuid: uuid,
        token: token,
        username: email,
        all_langs: 'yes',
      }),
      get body() {
        return this._body;
      },
      set body(value) {
        this._body = value;
      },
    })
      .then((response) => response.json())
    // return responseJson.data.payment_methods;
  }
  catch (error) {
    console.error(error);
  }
}

const styles = StyleSheet.create({
  listedItem: {
    fontSize: 16,
    marginTop: 10,
    // background: '#285b97',
    borderRadius: 3,
    padding: 5,
    marginLeft: 2,
    alignSelf: 'center'
  },
  listedItemName: {
    fontSize: 16,
    // background: '#285b97',
    borderRadius: 3,
    padding: 5,
    marginLeft: 2,
  },
  listedPageNum: {
    fontSize: 18,
    marginRight: 5,
    textDecorationLine: 'underline',
    color: 'blue'
  },
  listedItemCancel: {
    fontSize: 14,
    color: 'red',
    borderRadius: 3,
    padding: 5,
    marginLeft: 2,
  },
  listedItemComplete: {
    fontSize: 14,
    color: '#62C377',
    // background: '#285b97',
    borderRadius: 3,
    padding: 5,
    marginLeft: 2,
  },
  banner: {
    resizeMode: "stretch",
    width: '30%',
    height: 100,
  },
  border: {
    marginTop: 10,
    width: '100%',
    borderColor: '#000',
    borderRadius: 5,
    borderWidth: 2,
    padding: 10,
  },
  list_border: {
    marginTop: 10,
    width: 200,
    borderColor: '#000',
    borderRadius: 5,
    borderWidth: 2,
    padding: 10,
    flexGrow: 1,
    flexDirection: 'row'
  },
  image: {
    height: 100,
    width: 180,
    alignSelf: 'center',
    resizeMode: 'contain',
  },
});

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    height: 40,
    paddingLeft: 10,
    borderWidth: 2,
    borderColor: '#4189D6',
    borderRadius: 4,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
  },
  inputAndroid: {
    height: 40,
    paddingLeft: 10,
    borderWidth: 2,
    borderColor: '#4189D6',
    borderRadius: 8,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
  }
});



export default BrandList;